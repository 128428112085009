import { slugify } from "helpers/utility";
import RightDrawer from "Layout/RightDrawer";
import isoLangs from "lib/languages";
import React, { useEffect, useState } from "react";

interface ValObject {
  [key: string]: string; // Adjust according to the fields you have
}
interface LongDesc {
  title: string;
  body: string;
}
interface I18nEntry {
  lang: String;
  vals: ValObject;
}
interface FormValues {
  i18n: I18nEntry[];
}
interface Component {
  field: string;
  formValues: { [key: string]: any };
  setFormValues: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  element: {
    shortDesc: string;
    longDesc: LongDesc;
    name: string;
    isMultiLingual: boolean;
    autoGenerate?: boolean;
    autoGenerateBy: string;
    cols: number;
  };
  meta: {
    languages: String[];
    lang: String;
  };
}

const TextBox: React.FC<Component> = ({
  element,
  field,
  formValues,
  setFormValues,
  meta,
}) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: e.target.value,
    }));
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { value } = e.target;
    const activeLang = meta.languages[activeTab];

    setFormValues((prevValues: FormValues) => {
      // Ensure i18n exists and has the current language
      const updatedI18n: I18nEntry[] = prevValues.i18n || [];

      // Find or create the entry for the active language
      const updatedEntries = updatedI18n.map((entry: I18nEntry) => {
        if (entry.lang === activeLang) {
          // Ensure vals object contains the necessary field
          return {
            ...entry,
            vals: {
              ...entry.vals,
              [element.name]: value,
            },
          };
        }
        return entry;
      });

      // If no entry for the active language exists, add one
      const hasActiveLang = updatedEntries.some(
        (entry: I18nEntry) => entry.lang === activeLang
      );

      if (!hasActiveLang) {
        updatedEntries.push({
          lang: activeLang,
          vals: {
            [element.name]: value,
          },
        });
      }

      if (meta.lang === activeLang)
        return { ...prevValues, i18n: updatedEntries, [field]: value };
      return {
        ...prevValues,
        i18n: updatedEntries,
      };
    });
  };

  useEffect(() => {
    if (element.autoGenerate) {
      let newFormData = formValues;
      newFormData[field] = slugify(formValues[element.autoGenerateBy]);
      setFormValues(newFormData);
    }
  }, [formValues[element.autoGenerateBy]]);

  if (element.isMultiLingual) {
    return (
      <div className={`lg:col-span-${element.cols || 6}`}>
        <div className="flex flex-col gap-1 mb-2">
          <div className="flex items-center gap-2">
            <label className="inline-block text-base font-medium">
              {element.name}
            </label>
            {element.longDesc && (
              <RightDrawer
                title={element.longDesc.title}
                body={element.longDesc.body}
              />
            )}
          </div>
          {element.shortDesc && element.shortDesc !== "" && (
            <span className="text-sm">{element.shortDesc}</span>
          )}
        </div>
        <div className="flex gap-3 flex-wrap">
          {meta.languages.map((lang: any, i: number) => (
            <p
              className={`p-2 cursor-pointer ${
                activeTab === i
                  ? "underline text-mb-blue font-bold"
                  : "text-black"
              } `}
              onClick={() => setActiveTab(i)}
            >
              {isoLangs[lang].name}
            </p>
          ))}
        </div>
        <div>
          <input
            type="text"
            // value={formValues[field] || ""}
            value={
              meta.lang === meta.languages[activeTab]
                ? formValues[field]
                : formValues.i18n
                ? formValues.i18n.find(
                    (langObj: I18nEntry) =>
                      langObj.lang === meta.languages[activeTab]
                  )?.vals[element.name] || ""
                : ""
            }
            onChange={handleInputChange}
            name={element.name}
            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
            placeholder={`Enter ${element.name}`}
          />
        </div>
      </div>
    );
  }
  return (
    <div className={`lg:col-span-${element.cols || 6}`}>
      <div className="flex flex-col gap-1 mb-2">
        <div className="flex items-center gap-2">
          <label className="inline-block text-base font-medium">
            {element.name}
          </label>
          {element.longDesc && (
            <RightDrawer
              title={element.longDesc.title}
              body={element.longDesc.body}
            />
          )}
        </div>
        {element.shortDesc && element.shortDesc !== "" && (
          <span className="text-sm">{element.shortDesc}</span>
        )}
      </div>
      <input
        type="text"
        // id={element.name}
        value={formValues[field] || ""}
        onChange={handleChange}
        name={element.name}
        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
        placeholder={`Enter ${element.name}`}
        // required
      />
    </div>
  );
};

export default TextBox;
