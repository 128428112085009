import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import TableContainer from "Common/TableContainer";
import BreadCrumb from "Common/BreadCrumb";
import { getViewByNumber, getViews } from "helpers/mb-api_helper";
// Icons
import { Search, FileEdit, CheckCircle, Loader } from "lucide-react";
import isoLangs from "lib/languages";
import Pagination from "Layout/Pagination";
import DropdownWithCheckbox from "components/formComponets/DropdownWithCheckbox";

const tableHeadersOptions = [
  { label: "Name", value: "name" },
  { label: "Status", value: "status" },
  { label: "Platform", value: "platform" },
  { label: "Id", value: "id" },
  { label: "Slug", value: "slug" },
  { label: "Category", value: "category" },
  { label: "UpdateDate", value: "updateDate" },
  { label: "Language", value: "language" },
];

const ListViews: React.FC = () => {
  const [platforms, setPlatforms] = useState<any>([]); // State to store platforms data
  const [totalCount, setTotalCount] = useState<number>(0); // State to store total
  const [colData, setColData] = useState<any>([]);
  const [tableHeaders, setTableHeaders] = useState([
    "name",
    "slug",
    "platform",
    "status",
  ]);
  const [pageNumber, setPageNumber] = useState(1);
  const [show, setShow] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<any>(20);

  const columnObject: any = useMemo(
    () => ({
      name: {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
      },
      slug: {
        header: "Slug",
        accessorKey: "slug",
        enableColumnFilter: false,
      },
      platform: {
        header: "Platform",
        accessorKey: "v_platform",
        enableColumnFilter: false,
      },
      id: {
        header: "Id",
        accessorKey: "id",
        enableColumnFilter: false,
      },
      updateDate: {
        header: "UpdateDate",
        accessorKey: "updateDate",
        enableColumnFilter: false,
      },
      genres: {
        header: "Genres",
        accessorKey: "genres",
        enableColumnFilter: false,
        cell: (cell: any) => cell.row.original.genres?.join(" | "),
      },
      category: {
        header: "Category",
        accessorKey: "category",
        enableColumnFilter: false,
        cell: (cell: any) => cell.row.original.catogory?.join(" | "),
      },
      language: {
        header: "Language",
        accessorKey: "defaultLanguage",
        enableColumnFilter: false,
        cell: (cell: any) =>
          cell.row.original.defaultLanguage
            ? isoLangs[cell.row.original.defaultLanguage]["nativeName"]
            : "",
      },
      status: {
        header: "Status",
        accessorKey: "v_status",
        enableColumnFilter: false,
        cell: (cell: any) => <Status item={cell.getValue()} />,
      },
      action: {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <div className="flex gap-4">
            <Link
              className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
              to={`/platforms/views/${cell.row.original.v_platform}/${cell.row.original.id}`}
            >
              <FileEdit className="inline-block size-3 ltr:mr-1 rtl:ml-1" />
              {""}
              <span className="align-middle">Edit</span>
            </Link>
          </div>
        ),
      },
    }),
    []
  );

  // columns
  const Status = ({ item }: any) => {
    switch (item) {
      case "live":
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-mb-green dark:bg-mb-green/20 dark:border-transparent inline-flex items-center status">
            <CheckCircle className="size-3 mr-1.5" />
            {item}
          </span>
        );
      case "draft":
        return (
          <span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-slate-100 border-transparent text-slate-500 dark:bg-slate-500/20 dark:text-zink-200 dark:border-transparent status">
            <Loader className="size-3 mr-1.5" />
            {item}
          </span>
        );
      default:
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-mb-green dark:bg-mb-green/20 dark:border-transparent inline-flex items-center status">
            <CheckCircle className="size-3 mr-1.5" />
            {item}
          </span>
        );
    }
  };

  const handleHeaderOptionChange = (selectedOption: string[]) => {
    setTableHeaders(selectedOption);
  };

  const handlePageChange = async (value: number) => {
    if (value !== pageNumber)
      try {
        const response: any = await getViewByNumber(value);
        setPlatforms(response.list);
        setTotalCount(response.total);
        setPageNumber(value);
      } catch (e) {
        console.error("Error fetching assets:", e);
      }
  };

  useEffect(() => {
    const getPlatformData = async () => {
      try {
        const response: any = await getViews(); // Await the API call to fetch platforms
        setPlatforms(response.list); // Extract the data from the response and set it in the state
        setTotalCount(response.total); // Set the total count
      } catch (error) {
        console.error("Error fetching views:", error);
      }
    };
    getPlatformData();
  }, [show]);

  useEffect(() => {
    setColData(tableHeaders.map((header: any) => columnObject[header]));
  }, [tableHeaders, columnObject]);

  return (
    <React.Fragment>
      <BreadCrumb title="Manage Views" pageTitle="Storefront" />
      {/* <ToastContainer closeButton={false} limit={1} /> */}
      <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
        <div className="xl:col-span-12">
          <div className="card" id="usersTable">
            <div className="card-body">
              <div className="flex items-center">
                <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                  <div className="relative xl:col-span-2">
                    <input
                      type="text"
                      className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      placeholder="Search platforms..."
                      autoComplete="off"
                    />
                    <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end px-4">
              <DropdownWithCheckbox
                options={tableHeadersOptions}
                selectedOptions={tableHeaders}
                onChange={handleHeaderOptionChange}
                disabledValues={["name", "status"]}
              />
            </div>

            <div className="card-body">
              {platforms && platforms.length > 0 ? (
                <>
                  <TableContainer
                    isPagination={false}
                    columns={[...colData, columnObject.action] || []}
                    data={platforms || []}
                    customPageSize={
                      platforms.length > pageSize ? pageSize : platforms.length
                    }
                    divclassName="overflow-x-auto"
                    tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                    theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-mb-blue [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
                    thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
                    tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5"
                    PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
                  />
                  <div className="w-full flex justify-between">
                    <div>
                      <p className="text-sm text-gray-600 dark:text-gray-300">
                        Showing result {platforms?.length} out of {totalCount}
                      </p>
                    </div>
                    <Pagination
                      totalCount={totalCount}
                      pageNumber={pageNumber}
                      showResult={20}
                      handlePageChange={handlePageChange}
                    />
                  </div>
                </>
              ) : (
                <div className="noresult">
                  <div className="py-6 text-center">
                    <Search className="size-6 mx-auto text-mb-blue fill-sky-100 dark:mb-blue/20" />
                    <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ListViews;
