import { getContent, searchAssetByType } from "helpers/mb-api_helper";
import React, { useState, useEffect } from "react";

interface Option {
  label: string;
  value: string;
}

interface SearchableSelectProps {
  onSelect: any;
  value: string;
  assetsOptions: any[];
  index: number;
  assetType: string;
}

const SearchableSelect: React.FC<SearchableSelectProps> = ({
  onSelect,
  value,
  assetsOptions,
  index = 0,
  assetType,
}) => {
  const [searchKey, setSearchKey] = useState<string>("");
  const [options, setOptions] = useState<Option[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(e.target.value);
    setIsDropdownOpen(true);
  };

  const handleSelectOption = (option: Option) => {
    onSelect(option, index);
    setIsDropdownOpen(false);
    setSearchKey(option.label);
  };

  useEffect(() => {
    if (searchKey) {
      const fetchOptions = async () => {
        try {
          const response: any = await searchAssetByType(assetType, searchKey);
          const responseData = response.list || [];
          setOptions(
            responseData.map((media: any) => {
              return { label: media.title, value: media.id };
            })
          );
        } catch (error) {
          console.error("Error fetching options:", error);
        }
      };

      fetchOptions();
    }
  }, [searchKey]);

  useEffect(() => {
    const getContentById = async () => {
      try {
        const data: any = await getContent(value, assetType);
        setSearchKey(data?.title);
      } catch (e) {
        console.error("Error fetching content by id:", e);
      }
    };
    if (value !== "" && searchKey === "") getContentById();
  }, [value]);

  useEffect(() => {
    const options =
      assetsOptions.length > 0
        ? assetsOptions?.map((media: any) => {
            return { label: media.title, value: media.id };
          })
        : [];
    setOptions(options);
  }, [assetsOptions]);

  return (
    <div className="relative">
      <input
        type="text"
        value={searchKey}
        onChange={handleSearchChange}
        placeholder="Search..."
        className="border p-2 w-full"
      />
      {isDropdownOpen && options.length > 0 && (
        <ul className="absolute z-10 w-full bg-white border mt-1 max-h-60 overflow-y-auto">
          {options.map((option: any) => (
            <li
              key={option.value || option}
              onClick={() => handleSelectOption(option)}
              className="p-2 hover:bg-gray-200 cursor-pointer"
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchableSelect;
