import BreadCrumb from "Common/BreadCrumb";
import {
  createDraftMenu,
  getDraftMenuById,
  getViews,
  publishMenu,
  updateDraftMenu,
} from "helpers/mb-api_helper";
import isoLangs from "lib/languages";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { ReactSortable } from "react-sortablejs";

interface I18nEntry {
  language: String;
  title: string;
}
interface ActiveTabs {
  [key: number]: number;
}
interface FormValues {
  title: string;
  url: string;
  view: string;
  id: number;
  i18n: I18nEntry[];
}

const PlatformMenuIdEdit = () => {
  const selectUserMeta = (state: any) => state.User.meta;
  const meta = useSelector(selectUserMeta);
  const { id, menuId } = useParams();
  const [menuItemData, setMenuItemData] = useState<any>({});
  const [submitSuccess, setSubmitSuccess] = useState<any>(false);
  const [publishSuccess, setPublishSuccess] = useState<any>(false);
  const [fields, setFields] = useState<Array<FormValues>>([
    { title: "", url: "", view: "", i18n: [], id: 0 },
  ]);
  const [mainText, setMainText] = useState<string>("");
  const [options, setOptions] = useState<
    { placement: string; value: string }[]
  >([]);
  const [activeTabs, setActiveTabs] = useState<ActiveTabs>({
    "0": 0,
  });

  console.log("0000", activeTabs);

  const handleTabChange = (fieldIndex: number, langIndex: number) => {
    setActiveTabs((prevActiveTabs: any) => ({
      ...prevActiveTabs,
      [fieldIndex]: langIndex,
    }));
  };

  const handlePublishData = async () => {
    try {
      const data = await publishMenu(menuId);
      if (data) setPublishSuccess(true);
    } catch (err) {}
  };

  const handleInputChange = (value: string, index: number) => {
    const activeLang = meta.languages[activeTabs[index]];
    setFields((prevValues: FormValues[]) => {
      const updatedValues = prevValues.map((item, i) => {
        if (i === index) {
          // Ensure i18n exists
          const updatedI18n = item.i18n || [];

          // Update the i18n array
          const updatedEntries = updatedI18n.map((entry) => {
            if (entry.language === activeLang) {
              return { ...entry, title: value };
            }
            return entry;
          });

          // If no entry for the active language exists, add one
          const hasActiveLang = updatedEntries.some(
            (entry) => entry.language === activeLang
          );
          if (!hasActiveLang) {
            updatedEntries.push({
              language: activeLang,
              title: value,
            });
          }

          // If the current language is the default language, update the title directly
          if (meta.lang === activeLang) {
            return {
              ...item,
              title: value,
              i18n: updatedEntries,
            };
          }

          return {
            ...item,
            i18n: updatedEntries,
          };
        }

        return item;
      });

      return updatedValues;
    });
  };

  const addMoreFields = () => {
    setFields([
      ...fields,
      { title: "", url: "", view: "om", i18n: [], id: fields.length + 1 },
    ]);
    setActiveTabs((prevValue: any) => ({
      ...prevValue,
      [fields.length]: 0,
    }));
  };

  const handleDeleteField = (index: number) => {
    setFields(fields.filter((_, i) => i !== index));
    setActiveTabs((prevState: ActiveTabs) => {
      const { [index]: _, ...newState } = prevState;
      // Reindex the keys
      const reindexedState = Object.keys(newState).reduce(
        (acc: any, key: any, index: number) => {
          acc[index] = newState[key];
          return acc;
        },
        {}
      );

      return reindexedState;
    });
  };

  const handleFieldChange = (index: number, field: string, value: string) => {
    const newFields = [...fields];
    newFields[index] = { ...newFields[index], [field]: value };
    setFields(newFields);
  };

  const handleChange = (selectedOption: any, fieldIndex: number) => {
    if (!selectedOption) {
      console.error("Selected option is undefined");
      return;
    }

    // Update the state with the new selected option
    setFields((prevFields) =>
      prevFields.map((field, i) => {
        if (i === fieldIndex) {
          return { ...field, view: selectedOption.value }; // Update the `view` field
        }
        return field;
      })
    );
  };

  const handleSubmit = async () => {
    const formData = {
      ...menuItemData,
      title: mainText,
      items: fields,
      //   placement: menuItemData.placement,
      //   v_platform: menuItemData.v_platform,
    };
    try {
      const res = await updateDraftMenu(menuId, {
        ...formData,
      });
      const data: any = res;
      if (data) {
        setSubmitSuccess(true);
      }
    } catch (e) {}
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const currPlatFilters: any = {
          v_platform: id,
        };
        const currViewRes: any = await getViews(currPlatFilters); // Await the API call to fetch view by platform
        let currViewRecords = currViewRes.list;
        const recordOptions = currViewRecords.map(
          (record: { name: string; id: string }) => ({
            label: record.name,
            value: record.id,
          })
        );
        setOptions(recordOptions);
      } catch (e) {}
    };
    getData();
  }, [id]);

  useEffect(() => {
    const getMenuDataById = async () => {
      try {
        const response = await getDraftMenuById(menuId);
        const data: any = response;
        setMenuItemData(data);
        setFields(
          data.items || [{ title: "", url: "", view: "", i18n: [], id: 0 }]
        );
        setActiveTabs(() =>
          // Initialize state based on the length of data.items
          {
            return data?.items?.length > 0
              ? data.items.reduce((acc: any, _: any, index: any) => {
                  acc[index] = 0;
                  return acc;
                }, {})
              : { "0": 0 };
          }
        );
        setMainText(data.title || "");
      } catch (e) {
        const carateDraft = await createDraftMenu(menuId);
        const data: any = carateDraft;
        setMenuItemData(data);
        setFields(
          data.items || [{ title: "", url: "", view: "", i18n: [], id: 0 }]
        );
        setActiveTabs(() =>
          // Initialize state based on the length of data.items
          {
            return data?.items?.length > 0
              ? data.items.reduce((acc: any, _: any, index: any) => {
                  acc[index] = 0;
                  return acc;
                }, {})
              : { "0": 0 };
          }
        );
        setMainText(data.title || "");
      }
    };
    getMenuDataById();
  }, [menuId]);

  return (
    <React.Fragment>
      <BreadCrumb title={`Manage Views - ${id}`} pageTitle="" />
      {/* <ToastContainer closeButton={false} limit={1} /> */}
      <div className="">
        <div>
          <label className="inline-block mb-2 text-base font-medium">
            Main Text Field
          </label>
          <input
            type="text"
            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
            value={mainText}
            onChange={(e) => setMainText(e.target.value)}
          />
        </div>
        <ReactSortable list={fields} setList={setFields}>
          {fields.map((field, index) => (
            <div
              key={index}
              className="grid grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-12 mt-6 p-4 border rounded-lg shadow-lg"
            >
              <div className="lg:col-span-2 xl:col-span-12">
                <div className="flex justify-between">
                  <label
                    htmlFor="productNameInput"
                    className="inline-block mb-1 text-base font-medium"
                  >
                    Title
                  </label>
                  {fields.length > 1 && (
                    <button
                      className="btn bg-mb-red text-white"
                      onClick={() => handleDeleteField(index)}
                    >
                      Remove
                    </button>
                  )}
                </div>
                <div className="flex gap-3 flex-wrap">
                  {meta.languages.map((lang: any, i: number) => (
                    <p
                      key={i}
                      className={`p-2 ${
                        activeTabs[index] === i
                          ? "underline text-mb-blue font-bold"
                          : "text-black"
                      } `}
                      onClick={() => handleTabChange(index, i)}
                    >
                      {isoLangs[lang].name}
                    </p>
                  ))}
                </div>
                <div>
                  <input
                    type="text"
                    value={
                      field.i18n
                        ? field.i18n.find(
                            (langObj: I18nEntry) =>
                              langObj.language ===
                              meta.languages[activeTabs[index]]
                          )?.title || ""
                        : ""
                    }
                    onChange={(e) => handleInputChange(e.target.value, index)}
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    placeholder="Product title"
                  />
                </div>
              </div>
              <div className="xl:col-span-6">
                <label className="inline-block mb-2 text-base font-medium">
                  URL:
                </label>
                <input
                  type="text"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  value={field.url}
                  onChange={(e) =>
                    handleFieldChange(index, "url", e.target.value)
                  }
                />
              </div>
              <div className="xl:col-span-6">
                <label className="inline-block mb-2 text-base font-medium">
                  View
                </label>
                <Select
                  className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  id="choices-single-no-sorting"
                  name="choices-single-no-sorting"
                  options={options}
                  onChange={(selectedOption) =>
                    handleChange(selectedOption, index)
                  }
                  value={options.find((option) => option.value === field.view)}
                />
              </div>
            </div>
          ))}
        </ReactSortable>

        <div className="w-full mt-4">
          <button
            type="button"
            className="btn bg-mb-blue text-white hover:bg-mb-blue focus:bg-mb-blue"
            onClick={addMoreFields}
          >
            Add More
          </button>
        </div>

        <div className="w-full flex justify-end px-5 pb-5">
          {submitSuccess ? (
            publishSuccess ? (
              <button
                type="button"
                disabled={publishSuccess}
                className="btn bg-mb-blue text-white focus:bg-mb-blue"
                // onClick={handlePublishData}
              >
                Published
              </button>
            ) : (
              <button
                type="button"
                className="btn bg-mb-blue text-white hover:bg-mb-blue focus:bg-mb-blue"
                onClick={handlePublishData}
              >
                Publish
              </button>
            )
          ) : (
            <button
              type="button"
              className="btn bg-mb-blue text-white hover:bg-mb-blue focus:bg-mb-blue"
              onClick={handleSubmit}
            >
              Save
            </button>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default PlatformMenuIdEdit;
