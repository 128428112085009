import {
  addTitleInContent,
  assetByPageNumber,
  getAssetInfo,
  searchAssetByType,
} from "helpers/mb-api_helper";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import TableContainer from "Common/TableContainer";
import isoLangs from "lib/languages";
import { CheckCircle, FileEdit, Loader, Plus, Search, X } from "lucide-react";
import Modal from "Common/Components/Modal";
import { capitalizeFirstLetter } from "coustumFuntions/capitalFirstLetter";
import BreadCrumb from "Common/BreadCrumb";
import Pagination from "Layout/Pagination";
import DropdownWithCheckbox from "components/formComponets/DropdownWithCheckbox";

const tableHeadersOptions = [
  { label: "Title", value: "title" },
  { label: "Status", value: "status" },
  { label: "Id", value: "id" },
  { label: "Genres", value: "genres" },
  { label: "Category", value: "category" },
  { label: "Publish Date", value: "publishDate" },
  { label: "Expiry Date", value: "expiryDate" },
  { label: "UpdateDate", value: "updateDate" },
  { label: "Language", value: "language" },
];

const Status = ({ item }: any) => {
  switch (item) {
    case "live":
      return (
        <span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-mb-green dark:bg-mb-green/20 dark:border-transparent inline-flex items-center status">
          <CheckCircle className="size-3 mr-1.5" />
          {item}
        </span>
      );
    case "draft":
      return (
        <span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-slate-100 border-transparent text-slate-500 dark:bg-slate-500/20 dark:text-zink-200 dark:border-transparent status">
          <Loader className="size-3 mr-1.5" />
          {item}
        </span>
      );
    case "Rejected":
      return (
        <span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-red-100 border-transparent text-mb-red dark:bg-mb-red/20 dark:border-transparent status">
          <X className="size-3 mr-1.5" />
          {item}
        </span>
      );
    default:
      return (
        <span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-mb-green dark:bg-mb-green/20 dark:border-transparent inline-flex items-center status">
          <CheckCircle className="size-3 mr-1.5" />
          {item}
        </span>
      );
  }
};

interface RecordsData {
  [key: string]: any; // Allows any key-value pairs in the user object
}

const ManagementPage = () => {
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const { type } = useParams<{ type: string }>(); // Destructure `type` from useParams
  const [assets, setAssets] = useState<RecordsData>([]); // State to store assets data
  const [colData, setColData] = useState<any>([]); // State to store assets data
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [tableHeaders, setTableHeaders] = useState([
    "title",
    "genres",
    "language",
    "status",
  ]);

  const convertIdToDate = (id: number): string => {
    const date = new Date(id);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };

  const columnObject: any = useMemo(
    () => ({
      title: {
        header: "Title",
        accessorKey: "title",
        enableColumnFilter: false,
        enableSorting: true,
      },
      id: {
        header: "Id",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: true,
      },
      updateDate: {
        header: "UpdateDate",
        accessorKey: "updateDate",
        enableColumnFilter: false,
        enableSorting: true,
      },
      publishDate: {
        header: "PublishDate",
        accessorKey: "publishDate",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) =>
          cell.row.original?.publishDate !== ""
            ? convertIdToDate(cell.row.original.publishDate)
            : "",
      },
      expiryDate: {
        header: "ExpiryDate",
        accessorKey: "expiryDate",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) =>
          cell.row.original?.expiryDate !== ""
            ? convertIdToDate(cell.row.original.expiryDate)
            : "",
      },
      genres: {
        header: "Genres",
        accessorKey: "genres",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => cell.row.original.genres?.join(" | "),
      },
      category: {
        header: "Category",
        accessorKey: "category",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => cell.row.original.catogory?.join(" | "),
      },
      language: {
        header: "Language",
        accessorKey: "defaultLanguage",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) =>
          cell.row.original.defaultLanguage
            ? isoLangs[cell.row.original.defaultLanguage]["nativeName"]
            : "",
      },
      status: {
        header: "Status",
        accessorKey: "v_status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => <Status item={cell.row.original.v_status} />,
      },
      action: {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <Link
            className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
            to={`/manage/${type}/edit/${cell.row.original.id}`}
          >
            <FileEdit className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "}
            <span className="align-middle">Edit</span>
          </Link>
        ),
      },
    }),
    [type]
  );

  // const tableHeaders = ["title"];

  const handleHeaderOptionChange = (selectedOption: string[]) => {
    setTableHeaders(selectedOption);
  };

  const toggleAddContent = () => {
    setShow(false);
    setTitle("");
  };

  const handleSubmitData = async (): Promise<void> => {
    toggleAddContent();
    const data: string = JSON.stringify({ title: title, v_status: "draft" }); // Explicitly defining the data structure
    try {
      const response: any = await addTitleInContent(type, data);

      // Assuming toggleAddContent is a function to be called after adding the content

      // Navigate using the id from the response data
      navigate(`/manage/${type}/edit/${response.id}`);
    } catch (error) {
      console.error("Error fetching assets:", error);
    }
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSubmitData();
    }
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchKey(value);
    try {
      const response: AxiosResponse<RecordsData> = await searchAssetByType(
        type,
        value
      );
      setAssets(response);
    } catch (e) {
      console.error("Error filtering assets:", e);
    }
  };

  const handlePageChange = async (value: number) => {
    if (value !== pageNumber)
      try {
        const response = await assetByPageNumber(type, value);
        setAssets(response);
        setPageNumber(value);
      } catch (e) {
        console.error("Error fetching assets:", e);
      }
  };

  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const response: AxiosResponse<RecordsData> = await getAssetInfo(type); // Await the API call to fetch asset data
        setAssets(response); // Extract the data from the response and set it in the state
      } catch (error) {
        console.error("Error fetching assets:", error);
      }
    };

    if (type) {
      fetchAssets(); // Call the async function to fetch assets
    }
    setColData(tableHeaders.map((header: any) => columnObject[header]));
  }, [type, tableHeaders, columnObject]);

  useEffect(() => {
    if (show && inputRef.current) {
      inputRef.current.focus();
    }
  }, [show]);

  return (
    <React.Fragment>
      <BreadCrumb
        title={`Manage ${capitalizeFirstLetter(type)
          .replace(/([A-Z])/g, " $1")
          .trim()}`}
        pageTitle="Asset Management"
      />
      <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
        <div className="xl:col-span-12">
          <div className="card" id="usersTable">
            <div className="card-body">
              <div className="flex items-center justify-between">
                <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                  <div className="relative xl:col-span-2">
                    <input
                      type="text"
                      className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      placeholder="Search.. "
                      value={searchKey}
                      onChange={handleChange}
                    />
                    <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                  </div>
                </div>
                <div className="shrink-0">
                  <button
                    type="button"
                    className="text-white btn bg-mb-blue border-mb-blue hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                    onClick={() => setShow(true)}
                  >
                    <Plus className="inline-block size-4" />{" "}
                    <span className="align-middle">
                      {" "}
                      Add{" "}
                      {capitalizeFirstLetter(type)
                        .replace(/([A-Z])/g, " $1")
                        .trim()}
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="flex justify-end px-4">
              <DropdownWithCheckbox
                options={tableHeadersOptions}
                selectedOptions={tableHeaders}
                onChange={handleHeaderOptionChange}
                disabledValues={["title", "status"]}
              />
            </div>
            <div className="card-body">
              {assets && assets?.list?.length > 0 ? (
                <>
                  <TableContainer
                    isPagination={false}
                    columns={[...colData, columnObject.action] || []}
                    data={assets.list || []}
                    customPageSize={20}
                    // isSelect
                    divclassName="overflow-x-auto"
                    tableclassName="w-full whitespace-nowrap"
                    theadclassName="ltr:text-left rtl:text-right bg-slate-100 text-slate-500 dark:text-zink-200 dark:bg-zink-600"
                    thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold border-y border-slate-200 dark:border-zink-500"
                    tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zink-500"
                  />
                  <div className="w-full flex justify-between mt-4">
                    <div>
                      <p className="text-sm text-gray-600 dark:text-gray-300">
                        Showing result {assets?.list?.length} out of{" "}
                        {assets.total}
                      </p>
                    </div>
                    <Pagination
                      totalCount={assets?.total}
                      pageNumber={pageNumber}
                      showResult={20}
                      handlePageChange={handlePageChange}
                    />
                  </div>
                </>
              ) : searchKey === "" ? (
                <div className="noresult">
                  <div className="py-6 text-center flex flex-col gap-4 items-center justify-center">
                    <Search className="size-6 mx-auto text-mb-blue fill-mb-blue-100 dark:mb-blue/20" />
                    <h5 className="mt-2 mb-1">
                      Start by adding your first{" "}
                      <span className="capitalize text-mb-blue">
                        {type?.replace(/([A-Z])/g, " $1").trim()}
                      </span>
                    </h5>
                    <button
                      type="button"
                      className="text-white btn bg-mb-blue border-mb-blue hover:text-mb-blue hover:bg-mb-blue/10 hover:border-mb-blue focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                      onClick={() => setShow(true)}
                    >
                      <Plus className="inline-block size-4" />{" "}
                      <span className="align-middle">
                        {" "}
                        Add{" "}
                        {capitalizeFirstLetter(type)
                          .replace(/([A-Z])/g, " $1")
                          .trim()}
                      </span>
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="py-6 text-center flex flex-col gap-4 items-center justify-center">
                    <h5>No result found for {searchKey}</h5>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={toggleAddContent}
        id="defaultModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-mb-red"
        >
          <Modal.Title className="text-16">
            Add {capitalizeFirstLetter(type)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <div className="mb-3">
            <label
              htmlFor="designationInput"
              className="inline-block mb-2 text-base font-medium"
            >
              Title
            </label>
            <input
              ref={inputRef}
              type="text"
              id="title"
              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              placeholder="Enter Title"
              name="title"
              onChange={(e) => setTitle(e.target.value)}
              onKeyDown={handleKeyDown} // Listen for keydown events
              value={title}
            />
          </div>
          <div className="flex justify-end gap-2 mt-4">
            <button
              data-modal-close="addDocuments"
              className="text-mb-red transition-all duration-200 ease-linear bg-white border-white btn hover:text-mb-red/20 focus:text-mb-red/20 active:text-mb-red/20 dark:bg-zink-500 dark:border-zink-500"
              onClick={toggleAddContent}
            >
              Cancel
            </button>
            <button
              className="text-white transition-all duration-200 ease-linear btn bg-mb-blue border-mb-blue hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              onClick={handleSubmitData}
            >
              Add {capitalizeFirstLetter(type)}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ManagementPage;
