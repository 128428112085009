import { APIClient } from "./api_helper";

import * as url from "./url_helper";

const api = new APIClient();
// Gets the logged in user data from local session

// Gets the logged in user data from local session
export const getLoggedUser = () => {
  const user = localStorage.getItem("authUser");
  if (user) return JSON.parse(user);
  return null;
};

// is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedUser() !== null;
};

// Login Method
export const login = async (data: any) => {
  let response: any;
  response = await api.create(url.LOGIN, data);
  localStorage.setItem("authToken", response.token);
  return response;
};

// Get user information
export const getUser = () => api.get(url.GET_USER_DATA);
export const getMetaConfig = () => api.get(url.GET_META_CONFIG);

//Get asset information
export const getAssetInfo = (assetType: string | undefined) =>
  api.get(url.GET_ASSET_INFO + assetType);

//Get asset information
export const searchAssetByType = (
  assetType: string | undefined,
  searchKey: string | undefined
) =>
  api.get(
    searchKey === ""
      ? `${url.GET_ASSET_INFO}${assetType}?filters=`
      : `${url.GET_ASSET_INFO}${assetType}?filters=title:${searchKey} `
  );

// Get asset info by page number
export const assetByPageNumber = (
  assetType: string | undefined,
  pageNo: number
) => {
  const pageNumber = pageNo || 1;
  return api.get(`${url.GET_ASSET_INFO}${assetType}?page=${pageNumber}`);
};

//Get asset information
export const getDataByAPIPath = (
  apiRef: string | undefined,
  searchText: string | undefined
) => api.get(`${apiRef}&${searchText}`);

//Add title in content
export const addTitleInContent = (
  assetType: string | undefined,
  data: any | undefined
) => api.create(url.GET_ASSET_INFO + assetType, data);

//Get data for the edit content
export const getContent = (
  id: string | undefined,
  assetType: string | undefined
) => api.get(`${url.GET_ASSET_INFO}${assetType}/${id}`);

// Update Asset Information
export const updateAssetInformation = (
  id: string,
  assetType: string | undefined,
  data: any
) => api.put(`${url.GET_ASSET_INFO}${assetType}/${id}`, data);

//Get template data
// Search  assets
export const searchAssets = (filters?: any, search?: any) => {
  if (filters && search) {
    let filtersString: string = "";
    Object.keys(filters).map((f: any) => {
      if (filtersString) {
        filtersString += `;${f}:${filters[f]}`;
      } else {
        filtersString = `${f}:${filters[f]}`;
      }
    });
    return api.get(url.SEARCH_ASSETS, { filters: filtersString, text: search });
  } else if (search) {
    return api.get(url.SEARCH_ASSETS, { text: search });
  } else if (filters) {
    let filtersString: string = "";
    Object.keys(filters).map((f: any) => {
      if (filtersString) {
        filtersString += `;${f}:${filters[f]}`;
      } else {
        filtersString = `${f}:${filters[f]}`;
      }
    });
    return api.get(url.SEARCH_ASSETS, { filters: filtersString });
  } else {
    return api.get(url.SEARCH_ASSETS);
  }
};

//Get template data
export const getMetaUiData = (assetType: string | undefined) =>
  api.get(url.GET_META_UI + assetType);
export const getMetaTemplateData = (assetType: string | undefined) =>
  api.get(url.GET_META_TEMPLATE + assetType);

// Get all platforms
export const getPlatforms = (filters?: any) => {
  if (filters) {
    let filtersString: string = "";
    Object.keys(filters).map((f: any) => {
      if (filtersString) {
        filtersString += `;${f}:${filters[f]}`;
      } else {
        filtersString = `${f}:${filters[f]}`;
      }
    });
    return api.get(url.PLATFORM, { filters: filtersString });
  } else {
    return api.get(url.PLATFORM);
  }
};
// create new platform
export const createPlatform = (data: any) => {
  return api.create(url.PLATFORM, data);
};
// get platform by Id
export const getPlatformById = (id: any) => {
  return api.get(`${url.PLATFORM}/${id}`);
};

// get platform by page number
export const getPlatformByPageNumber = (pageNo: number) => {
  const pageNumber = pageNo || 1;
  return api.get(`${url.PLATFORM}?page=${pageNumber}`);
};

// get platform by page number

//get menu by id
export const getMenuById = (id: any) => {
  return api.get(`${url.MENU}/${id}`);
};

// create new menu item
export const createMenuItem = (data: any) => {
  return api.create(url.MENU, data);
};
// create new menu item
export const updateMenuItem = (data: any) => {
  return api.put(url.MENU, data);
};
export const getAllMenu = () => {
  return api.get(url.MENU);
};

// get menu by page number
export const getMenuByPageNumber = (pageNo: number) => {
  const pageNumber = pageNo || 1;
  return api.get(`${url.MENU}?page=${pageNumber}`);
};

// delete menu item

// publish menu
export const publishMenu = (id: any) => {
  return api.create(`${url.PUBLISH_MENU}/${id}`);
};

// get draft menu by id
export const getDraftMenuById = (id: any) => {
  return api.get(`${url.DRAFT_MENU}/${id}`);
};

// update draft menu
export const updateDraftMenu = (id: any, data: any) => {
  return api.put(`${url.DRAFT_MENU}/${id}`, data);
};

// create draft menu by id
export const createDraftMenu = (id: any) => {
  return api.create(`${url.DRAFT_MENU}/${id}`);
};

// get view by Id
export const getViewById = (id: any) => {
  return api.get(`${url.VIEW}/${id}`);
};

// get view by number
export const getViewByNumber = (pageNo: number) => {
  const pageNumber = pageNo || 1;
  return api.get(`${url.VIEW}?page=${pageNumber}`);
};

// Get all views
export const getViews = (filters?: any) => {
  let modifiedUrl: any;
  if (filters) {
    let filtersString: string = "";
    Object.keys(filters).map((f: any) => {
      if (filtersString) {
        filtersString += `;${f}:${filters[f]}`;
      } else {
        filtersString = `${f}:${filters[f]}`;
      }
    });
    modifiedUrl = `${url.VIEW}?filters=${filtersString}`;
  } else {
    modifiedUrl = url.VIEW;
  }
  // console.log('url', modifiedUrl)
  return api.get(modifiedUrl);
};
// create new view
export const createView = (data: any) => {
  return api.create(url.VIEW, data);
};
// publish view
export const publishView = (id: any) => {
  return api.create(`${url.PUBLISH_VIEW}/${id}`);
};

// get draft view by Id
export const getDraftViewById = (id: any) => {
  return api.get(`${url.DRAFT_VIEW}/${id}`);
};
// create create version of view
export const createDraftView = (id: any) => {
  return api.create(`${url.DRAFT_VIEW}/${id}`);
};
// update draft version of view
export const updateDraftView = (id: any, data: any) => {
  return api.put(`${url.DRAFT_VIEW}/${id}`, data);
};

// Get all rails
export const getRails = (filters?: any) => {
  let modifiedUrl: any;
  if (filters) {
    let filtersString: string = "";
    Object.keys(filters).map((f: any) => {
      if (filtersString) {
        filtersString += `;${f}:${filters[f]}`;
      } else {
        filtersString = `${f}:${filters[f]}`;
      }
    });
    modifiedUrl = `${url.RAIL}?filters=${filtersString}`;
  } else {
    modifiedUrl = url.RAIL;
  }
  // console.log('url', modifiedUrl)
  return api.get(modifiedUrl);
};
// get rail Instance by Id
export const getRailInstanceById = (id: any) => {
  return api.get(`${url.RAIL_INSTANCE}/${id}`);
};
// create rail instance
export const createRailInstance = (data: any) => {
  return api.create(`${url.RAIL_INSTANCE}`, data);
};
// update  rail instance
export const updateRailInstance = (id: any, data: any) => {
  return api.put(`${url.RAIL_INSTANCE}/${id}`, data);
};

// Register Method
export const postJwtRegister = (url: any, data: any) => {
  return api.create(url, data).catch((err: any) => {
    var message;
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = err[1];
          break;
      }
    }
    throw message;
  });
};

//Get Presigned URL
export const getPreSignedUrl = (file: string) => {
  return api.create(url.PRESIGNED_URL, { file: file });
};
//Upload file
export const uploadFile = (url: string, file: any) => {
  return api.upload(url, file);
};
