import React from "react";
import CreatableSelect from "react-select/creatable";

interface PaginationProps {
  totalCount: number; // Total number of results
  pageNumber: number; // Current page number
  showResult: number; // Number of results per page
  handlePageChange: (page: number) => void; // Function to handle page change
}

const Pagination: React.FC<PaginationProps> = ({
  totalCount,
  pageNumber,
  showResult,
  handlePageChange,
}) => {
  const totalPages = Math.ceil(totalCount / showResult);

  const getOptions = () => {
    const options: {}[] = [];
    for (let i = 1; i <= totalPages; i++) {
      options.push({ value: i, label: i.toString() });
    }
    return options;
  };

  const getPageNumbers = () => {
    const pages: (number | string)[] = [];
    const maxPagesToShow = 5;

    if (totalPages <= maxPagesToShow) {
      // Show all pages if they fit within maxPagesToShow
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (pageNumber <= 3) {
        // Case: Page near the start
        for (let i = 1; i <= 3; i++) pages.push(i);
        pages.push("...");
        pages.push(totalPages);
      } else if (pageNumber > totalPages - 3) {
        // Case: Page near the end
        pages.push(1);
        pages.push("...");
        for (let i = totalPages - 2; i <= totalPages; i++) pages.push(i);
      } else {
        // Case: Page somewhere in the middle
        pages.push(1);
        pages.push("...");
        pages.push(pageNumber - 1);
        pages.push(pageNumber);
        pages.push(pageNumber + 1);
        pages.push("...");
        pages.push(totalPages);
      }
    }

    return pages;
  };
  const handleChange = (selectedOption: any) => {
    handlePageChange(Number(selectedOption.value));
  };

  const handleClick = (page: number | string) => {
    if (page !== "...") {
      handlePageChange(Number(page));
    }
  };

  const pageNumbers = getPageNumbers();
  const PaginationOptions = getOptions();

  return (
    <div className="flex items-center space-x-2">
      <div>
        <CreatableSelect
          className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
          id="choices-single-no-search"
          name="choices-single-no-search"
          options={PaginationOptions}
          onChange={handleChange}
          value={
            PaginationOptions.find(
              (option: any) => option.value === pageNumber
            ) || null
          }
        />
      </div>
      <button
        onClick={() => handlePageChange(pageNumber - 1)}
        disabled={pageNumber === 1}
        className="px-3 py-1 border rounded disabled:opacity-50"
      >
        &lt;
      </button>
      {pageNumbers.map((page, index) => (
        <button
          key={index}
          onClick={() => handleClick(page)}
          className={`px-3 py-1 border rounded ${
            page === pageNumber ? "bg-mb-blue text-white" : ""
          }`}
        >
          {page}
        </button>
      ))}
      <button
        onClick={() => handlePageChange(pageNumber + 1)}
        disabled={pageNumber === totalPages}
        className="px-3 py-1 border rounded disabled:opacity-50"
      >
        &gt;
      </button>
    </div>
  );
};

export default Pagination;
