import React, { useState } from "react";

interface Option {
  label: string;
  value: string;
}

interface DropdownWithCheckboxProps {
  options: Option[];
  selectedOptions: string[]; // Updated to array of strings
  onChange: (selectedOptions: string[]) => void; // Updated to array of strings
  disabledValues: string[]; // Updated to array of strings
}

const DropdownWithCheckbox: React.FC<DropdownWithCheckboxProps> = ({
  options,
  selectedOptions,
  onChange,
  disabledValues,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleOptionChange = (optionValue: string) => {
    if (selectedOptions.includes(optionValue)) {
      onChange(selectedOptions.filter((value) => value !== optionValue));
    } else {
      onChange([...selectedOptions, optionValue]);
    }
  };

  return (
    <div className="relative w-64">
      <div
        className="border border-gray-300 rounded p-2 cursor-pointer"
        onClick={handleToggle}
      >
        {"Select header"}
      </div>
      {isOpen && (
        <div className="absolute mt-2 w-full bg-white border border-gray-300 rounded shadow-lg z-10">
          {options.map((option) => (
            <label
              key={option.value}
              className="block p-2 hover:bg-gray-100 cursor-pointer"
            >
              <input
                type="checkbox"
                disabled={disabledValues.includes(option.value)}
                checked={selectedOptions.includes(option.value)}
                onChange={() => handleOptionChange(option.value)}
                className="mr-2"
              />
              {option.label}
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownWithCheckbox;
