import BreadCrumb from "Common/BreadCrumb";
import TableContainer from "Common/TableContainer";
import { createMenuItem, getAllMenu } from "helpers/mb-api_helper";
import { FileEdit, Search } from "lucide-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const MenuSection = () => {
  const selectUserMeta = (state: any) => state.User.meta;
  const meta = useSelector(selectUserMeta);
  const { id } = useParams();
  const navigate = useNavigate();
  const [tableRowData, setTableRowData] = useState([]);

  // Memoize handleCreateMenuItem
  const handleCreateMenuItem = useCallback(
    async (menuId: string) => {
      try {
        const res = await createMenuItem({
          id: menuId,
          title: "",
          placement: menuId,
          v_platform: id,
        });
        const data: any = res;
        navigate(`/platforms/menus/${id}/${data.id}`);
      } catch (err) {
        console.error(err); // Consider handling errors appropriately
      }
    },
    [id, navigate]
  ); // Add dependencies here

  // Memoize handleEditMenu
  const handleEditMenu = useCallback(
    async (menuId: string, itemId: string) => {
      if (itemId === "") {
        handleCreateMenuItem(menuId);
      } else {
        navigate(`/platforms/menus/${id}/${itemId}`);
      }
    },
    [id, handleCreateMenuItem, navigate]
  ); // Add dependencies here

  const columns = useMemo(
    () => [
      {
        header: "Id",
        accessorKey: "ids",
        enableColumnFilter: false,
      },
      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <div className="flex gap-4">
            <div
              className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
              onClick={() => {
                handleEditMenu(cell.row.original.name, cell.row.original.ids);
              }}
              //   to={`/platforms/menus/${id}/${cell.row.original.name}`}
            >
              <FileEdit className="inline-block size-3 ltr:mr-1 rtl:ml-1" />
              {""}
              <span className="align-middle">Edit</span>
            </div>
          </div>
        ),
      },
    ],
    [handleEditMenu]
  );

  // Memoize menuPlacementsData
  const menuPlacementsData = useMemo(() => {
    return (
      meta?.menuPlacements?.map((menu: string) => ({
        name: menu,
      })) || []
    );
  }, [meta?.menuPlacements]); // Add dependencies here
  useEffect(() => {
    const getAllMenuData = async () => {
      try {
        const response = await getAllMenu();

        const menuData: any = response;
        const placementsToIds: { [key: string]: string } = {};

        menuData.list.forEach((item: any) => {
          const key = `${item.v_platform}-${item.placement}`;
          if (!placementsToIds[key]) {
            placementsToIds[key] = "";
          }
          placementsToIds[key] = item.id;
        });

        const updatedFirstArray = menuPlacementsData?.map((item: any) => {
          const key = `${id}-${item.name}`; // Adjust as needed
          return {
            ...item,
            ids: placementsToIds[key] || "",
          };
        });

        setTableRowData(updatedFirstArray);
      } catch (err) {
        console.error("Error fetching menu data", err);
      }
    };

    getAllMenuData();
  }, [id, menuPlacementsData]);

  return (
    <React.Fragment>
      <BreadCrumb title={`Manage Views - ${id}`} pageTitle="" />
      {/* <ToastContainer closeButton={false} limit={1} /> */}
      <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
        <div className="xl:col-span-12">
          <div className="card" id="usersTable">
            <div className="card-body">
              {tableRowData && tableRowData?.length > 0 ? (
                <TableContainer
                  isPagination={true}
                  columns={columns || []}
                  data={tableRowData || []}
                  divclassName="-mx-5 -mb-5 overflow-x-auto"
                  tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                  theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-mb-blue [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
                  thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
                  tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5"
                  PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
                />
              ) : (
                <div className="noresult">
                  <div className="py-6 text-center">
                    <Search className="size-6 mx-auto text-mb-blue fill-sky-100 dark:mb-blue/20" />
                    <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      ;
    </React.Fragment>
  );
};

export default MenuSection;
