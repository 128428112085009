import Drawer from "Common/Components/Drawer";
import { X } from "lucide-react";
import React, { useState } from "react";

interface Component {
  title?: string;
  body?: string | TrustedHTML;
}

const RightDrawer: React.FC<Component> = ({ title, body }) => {
  // drawerEnd
  const [drawerEnd, setDrawerEnd] = useState<boolean>(false);
  const drawerEndToggle = () => setDrawerEnd(!drawerEnd);
  return (
    <>
      <button
        data-drawer-target="drawerEnd"
        type="button"
        className="text-mb-blue"
        onClick={drawerEndToggle}
      >
        {" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <circle cx="12" cy="12" r="10" />
          <line x1="12" x2="12" y1="8" y2="12" />
          <line x1="12" x2="12.01" y1="16" y2="16" />
        </svg>
      </button>

      <Drawer
        show={drawerEnd}
        onHide={drawerEndToggle}
        id="drawerEnd"
        drawer-end="true"
        className="fixed inset-y-0 flex flex-col w-full transition-transform duration-300 ease-in-out transform bg-white shadow ltr:right-0 rtl:left-0 md:w-80 z-drawer dark:bg-zink-600"
      >
        <Drawer.Header
          className="flex items-center justify-between p-4 border-b card-body border-slate-200 dark:border-zink-500"
          closeButtonClass="lucide lucide-x size-4 transition-all duration-200 ease-linear text-slate-500 hover:text-slate-700 dark:text-zink-200 dark:hover:text-zink-50"
        >
          <Drawer.Title className="text-15">
            {title || "Header of component"}
          </Drawer.Title>
          <X className="size-4 transition-all duration-200 ease-linear text-slate-500 hover:text-slate-700 dark:text-zink-200 dark:hover:text-zink-50"></X>
        </Drawer.Header>
        <Drawer.Body className="h-full p-4 overflow-y-auto">
          {body && <div dangerouslySetInnerHTML={{ __html: body }} />}
        </Drawer.Body>
        {/* <Drawer.Footer className="flex items-center justify-between p-4 border-t border-slate-200 dark:border-zink-500">
          <h6 className="text-15">Drawer Footer</h6>
        </Drawer.Footer> */}
      </Drawer>
    </>
  );
};

export default RightDrawer;
