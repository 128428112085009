import React, { useState, useEffect } from "react";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { searchAssets, getAssetInfo } from "helpers/mb-api_helper";
import Modal from "Common/Components/Modal";
import Select from "react-select";
import { ReactSortable } from "react-sortablejs"


// Icons
import { Plus } from "lucide-react";
import { title } from "process";

interface Component {
  id: number;
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
}

const BasicPlaceholderComponent: React.FC<Component> = ({
  id,
  formData,
  setFormData,
}) => {
  let statusOptions = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
  ];
  const selectProperties = createSelector(
    (state: any) => state.User,
    (user) => ({
      meta: user.meta,
    })
  );
  const { meta } = useSelector(selectProperties);
  let assetType = meta.assetTypes;
  let [assetTypeOption, setAssetTypeOption] = useState<any>(
    assetType
      ? assetType.map((v: any) => {
          return { label: v, value: v };
        })
      : []
  );
  let [show, setShow] = useState<boolean>(false);
  let [selectedStatusOptions, setSelectedStatusOptions] = useState<any>();
  let [selectedAssetType, setSelectedAssetType] = useState<any>(null);
  let [assetTypeData, setAssetTypeData] = useState<any>([]);
  let [assetTypeDataCount, setassetTypeDataCount] = useState<any>(0);
  let [placeholderData, setPlaceholderData] = useState<any>(formData[id] && formData[id].placeholderData
    ? formData[id].placeholderData
    : []
  );
  let [searchTerm, setSearchTerm] = useState<any>("");
  let [startDate, setStartDate] = useState<any>(null);
  let [endDate, setEndDate] = useState<any>(null);

  let handleSearch = async (searchQuery: any) => {
    try {
      // Await the API call to fetch assets
      let currTypeFilters: any = {
        v_type: selectedAssetType.value,
      };
      let assetsRes: any = await searchAssets(currTypeFilters, searchQuery);
      setAssetTypeData(assetsRes.list);
      setassetTypeDataCount(assetsRes.total);
    } catch (error) {
      console.log(error);
    }
  };

  let handleAssetChange = async (asset: any) => {
    try {
      // Await the API call to fetch assets of type
      let assetsRes: any = await getAssetInfo(asset.value);
      setassetTypeDataCount(assetsRes.total);
      setAssetTypeData(assetsRes.list);
      setSelectedAssetType(asset);
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   setPlaceholderData(
  //     formData[id] && formData[id].placeholderData
  //       ? formData[id].placeholderData
  //       : []
  //   );
  // }, [formData]);

  useEffect(() => {
    console.log("placeholderData", placeholderData);
    const newData = [...formData];
                      newData[id] = {
                        ...newData[id],
                        placeholderData: [...placeholderData],
                      };
    setFormData(newData);

  }, [placeholderData]);

  // useEffect(() => {
  //   const getData = async () => {
  //     // Await the API call to fetch assets
  //     let assetsRes: any = await searchAssets();
  //     console.log("assetsRes", assetsRes);
  //     if (assetsRes.list) {
  //       setAssetTypeData(assetsRes.list);
  //       setassetTypeDataCount(assetsRes.total);
  //     }
  //   };
  //   getData();
  // }, []);

  return (
    <>
      <div className="grid lg:grid-cols-2 gap-4 justify-between ml-5 mr-5 mb-5">
        <label className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize">
          Placeholder
        </label>
      </div>

      <div className="grid lg:grid-cols-2 gap-4 ml-5 mr-5 mb-5">
        <div className="flex items-start justify-start">
        <button
            type="button"
            onClick={() => setShow(true)}
            className="text-white self-start btn bg-mb-blue w-auto border-mb-blue hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
          >
            <Plus className="inline-block size-4" />
            <span className="align-middle">Add</span>
          </button>
          </div>
        <div className="flex flex-row gap-4 lg:col-span-2">
          {placeholderData.length > 0 &&
          <ReactSortable list={placeholderData} setList={setPlaceholderData}>
            {placeholderData.map((data: any) => {
              return (
                <button className="text-white btn mx-2 bg-orange-500 border-orange-500 hover:text-white hover:bg-orange-600 hover:border-orange-600 focus:text-white focus:bg-orange-600 focus:border-orange-600 focus:ring focus:ring-custom-100 active:text-white active:bg-orange-600 active:border-orange-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                  {data.title}
                </button>
              );
            })}
            </ReactSortable>
            }
          
        </div>
      </div>

      {/* select assets Modal */}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        id="defaultModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-mb-red"
        >
          <Modal.Title className="text-16">Select Assets</Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto mb-5">
          <div className="grid lg:grid-cols-2 gap-10 justify-center mb-5">
            {assetType && (
              <>
                <div className="flex flex-col">
                  <Select
                    // isMulti
                    value={selectedAssetType}
                    onChange={(asset: any) => handleAssetChange(asset)}
                    options={assetTypeOption}
                    placeholder="Select Type"
                    className="selectize capitalize"
                  />
                </div>
                <div>
                  <input
                    type="text"
                    className="form-input placeholder:capitalize"
                    placeholder="Search assets..."
                    value={searchTerm}
                    onChange={(e: any) => {
                      setSearchTerm(e.target.value);
                      handleSearch(e.target.value);
                    }}
                  />
                </div>
                {/* <div className='flex flex-col'>
                                    <p className="text-lg">Total: {assetTypeDataCount}</p>
                                </div> */}
              </>
            )}
          </div>
          <div className="flex flex-col rounded-md border border-white-light dark:border-[#1b2e4b] h-[70vh] ">
            {assetTypeData &&
              assetTypeData.map((item: any, idx: any) => (
                <div className="grid">
                  <div
                    key={idx}
                    className="gap-10 border-b flex border-white-light dark:border-[#1b2e4b] px-4 py-2.5 bg-white text-black hover:bg-primary hover:text-mb-blue shadow-[0_1px_15px_1px_rgba(67,97,238,0.15)]"
                  >
                    <input
                      id="assetSelectIds"
                      className="form-checkbox"
                      type="checkbox"
                      checked={placeholderData
                        .map((placeholder: any) => placeholder.id)
                        .includes(item.id)}
                      onChange={(e: any) => {
                        if (e.target.checked) {
                          let newPlaceholderData = placeholderData;
                          let newAssetItem = {
                            id: item.id,
                            title: item.title,
                          };
                          newPlaceholderData.push(newAssetItem);
                          setPlaceholderData([...newPlaceholderData]);
                          // const newData = [...formData];
                          // newData[id] = {
                          //   ...newData[id],
                          //   placeholderData: [...newPlaceholderData],
                          // };
                          // setFormData(newData);
                        } else {
                          let newPlaceholderData = placeholderData.filter(
                            (placeholder: any) => placeholder.id !== item.id
                          );
                          setPlaceholderData(newPlaceholderData);
                          // const newData = [...formData];
                          // newData[id] = {
                          //   ...newData[id],
                          //   placeholderData: [...newPlaceholderData],
                          // };
                          // setFormData(newData);
                        }
                      }}
                      disabled={
                        placeholderData.length >= formData &&
                        formData[id] &&
                        formData[id].placeholders &&
                        !placeholderData
                          .map((placeholder: any) => placeholder.id)
                          .includes(item.id)
                      }
                    />
                    <label>{item.title} </label>
                  </div>
                </div>
              ))}
            {/* <div className="flex justify-end gap-8 mt-4 mr-5">
              <button
                onClick={() => setShow(false)}
                className="text-white bg-mb-red border-mb-red btn hover:text-white hover:bg-mb-red/20 hover:border-mb-red/20 focus:text-white focus:bg-mb-red/20 focus:border-mb-red/20 focus:ring focus:ring-red-100 active:text-white active:bg-mb-red/20 active:border-mb-red/20 active:ring active:ring-red-100 dark:ring-custom-400/20"
              >
                Cancel
              </button>
            </div> */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BasicPlaceholderComponent;
