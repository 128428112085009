import React from 'react';
import GenericComponent from './GenericComponent';
import FilterAndSortComponent from './FilterAndSortComponent';
import PlaceholderComponent from './PlaceholderComponent';

interface RailsComponentProps {
  id: number
  railData: any
  formData: any 
  setFormData: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  setUndo: React.Dispatch<React.SetStateAction<boolean>>;
  setApiSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  active: any
  setActive: React.Dispatch<React.SetStateAction<any>>;
}

const RailsComponent: React.FC<RailsComponentProps> = ({
  id,
  railData,
  formData,
  setFormData,
  setUndo,
  setApiSuccess,
  active,
  setActive,
}) => {
  const renderComponent = () => {
    switch (railData.uiElement) {
        case "generic":
          return (
            <GenericComponent
              key={id}
              id={id}
              formData={formData}
              setFormData={setFormData}
              setUndo={setUndo}
              setApiSuccess={setApiSuccess}
              active={active}
              setActive={setActive}
            />
          );
        case "filterAndSort":
          return (
            <FilterAndSortComponent
              key={id}
              id={id}
              formData={formData}
              setFormData={setFormData}
              setUndo={setUndo}
              setApiSuccess={setApiSuccess}
              active={active}
              setActive={setActive}
            />
        );
        case "placeholder":
          return (
            <PlaceholderComponent
              key={id}
              id={id}
              formData={formData}
              setFormData={setFormData}
              setUndo={setUndo}
              setApiSuccess={setApiSuccess}
              active={active}
              setActive={setActive}
            />
          );
        default:
          return <p>No component found</p>;
      }
    };
  
    return <>{renderComponent()}</>;
};

export default RailsComponent;
