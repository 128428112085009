import React from "react";
interface Component {
  field: string;
  formValues: {};
  element: {
    name: string;
    cols: number;
  };
}

const ActionBox: React.FC<Component> = ({ element }) => {
  return <div className={`lg:col-span-${element.cols || 12}`}>ActionBox</div>;
};

export default ActionBox;
