import RightDrawer from "Layout/RightDrawer";
import React from "react";

interface Component {
  field: string;
  formValues: { [key: string]: any };
  setFormValues: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  element: {
    shortDesc?: string;
    longDesc?: {
      title: string;
      body: string;
    };
    name: string;
    cols: number;
  };
}

const CheckBox: React.FC<Component> = ({
  element,
  field,
  formValues,
  setFormValues,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: e.target.checked,
    }));
  };

  return (
    <div className={`lg:col-span-${element.cols || 6} self-center h-full w-full`}>
      <div className="flex items-center justify-start h-full w-full">
        <div className="flex items-end gap-3">
          <input
            id={`checkbox-${field}`}
            className="size-4 border rounded-sm appearance-none cursor-pointer bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-mb-blue checked:border-mb-blue dark:checked:bg-mb-blue dark:checked:border-mb-blue checked:disabled:bg-custom-400 checked:disabled:border-custom-400"
            type="checkbox"
            checked={formValues[field] || false}
            onChange={handleChange}
          />
          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-2">
              <label
                htmlFor={`checkbox-${field}`}
                className="align-middle text-base font-medium"
              >
                {element.name}
              </label>
              {element.longDesc && (
                <RightDrawer
                  title={element.longDesc.title}
                  body={element.longDesc.body}
                />
              )}
            </div>
            {element.shortDesc && element.shortDesc !== "" && (
              <span className="text-sm">{element.shortDesc}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckBox;
