import React, { useEffect, useState } from "react";
import ObjectBox from "./ObjectBox";
import RightDrawer from "Layout/RightDrawer";
interface Component {
  field: string;
  formValues: { [key: string]: any };
  setFormValues: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  element: {
    shortDesc: string;
    longDesc: {
      title: string;
      body: string;
    };
    name: string;
    [key: string]: any;
    cols: number;
  };
}

const CueTimingsBox: React.FC<Component> = ({
  element,
  field,
  formValues,
  setFormValues,
}) => {
  const [cueTimingsBoxData, setCueTimingsBoxData] = useState(
    formValues[field] || {}
  );

  useEffect(() => {
    if (cueTimingsBoxData !== undefined) {
      setFormValues((prevValues) => ({
        ...prevValues,
        [field]: cueTimingsBoxData,
      }));
    }
  }, [cueTimingsBoxData, field, setFormValues]);

  return (
    <div className={`lg:col-span-${element.cols || 12}`}>
      <div className="flex flex-col gap-1 mb-2">
        <div className="flex items-center gap-2">
          <h5>{element.name}</h5>
          {element.longDesc && (
            <RightDrawer
              title={element.longDesc.title}
              body={element.longDesc.body}
            />
          )}
        </div>
        {element.shortDesc && element.shortDesc !== "" && (
          <span className="text-sm">{element.shortDesc}</span>
        )}
      </div>
      <div className="grid grid-cols-1 gap-5 lg:grid-cols-12  mt-4">
        {Object.keys(element.properties).map((f, i) => {
          if (element.properties[f].showInUi)
            return (
              <ObjectBox
                key={i}
                field={f}
                formValues={cueTimingsBoxData}
                setFormValues={setCueTimingsBoxData}
                element={{
                  ...element.properties[f],
                  ...element.properties[f].items,
                }}
                isMultiple={true}
              />
            );
          return null;
        })}
      </div>
    </div>
  );
};

export default CueTimingsBox;
