import React, { ReactNode, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { setUserData } from "slices/auth/user/thunk";

interface AuthProtectedProps {
  children: ReactNode;
}

const AuthProtected: React.FC<AuthProtectedProps> = ({ children }) => {
  if (!localStorage.getItem("authToken")) {
    return <Navigate to={{ pathname: "/login" }} />;
  }
  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthProtected;
