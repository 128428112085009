import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { access } from "fs";


interface UserState {
  account: string ;
  name: string;
  id: number;
  acl: any;
  meta:any;
}

const initialState: UserState = {
  account: localStorage.getItem("authUser")? JSON.parse(localStorage.getItem("authUser") as string).account: null,
  name: localStorage.getItem("authUser")? JSON.parse(localStorage.getItem("authUser") as string).name: null,
  id: localStorage.getItem("authUser")? JSON.parse(localStorage.getItem("authUser") as string).id: null,
  acl: localStorage.getItem("authUser")? JSON.parse(localStorage.getItem("authUser") as string).acl: null,
  meta: localStorage.getItem("authUser")? JSON.parse(localStorage.getItem("authUser") as string).meta: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state: UserState, action: PayloadAction<{account:string,name:string,id:number,acl:any}>) {
        state.account = action.payload.account
        state.name = action.payload.name
        state.id = action.payload.id
        state.acl = action.payload.acl
    },
    setMeta(state:UserState,action:PayloadAction<any>){
        state.meta = action.payload
    },
    setMetaError(state:UserState,action:PayloadAction<any>){
        state.account = ""
        state.name = ""
        state.id =0
        state.acl = []
        state.meta = {}
    }
  },
});

export const { setUser, setMeta,setMetaError } = userSlice.actions;
export default userSlice.reducer;
