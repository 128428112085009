import RightDrawer from "Layout/RightDrawer";
import FormComponent from "pages/ContentManagement/FormComponent";
import React, { useEffect, useState } from "react";

interface EmptyObj {
  [key: string]: string; // Allows for dynamic property names
}
interface Component {
  field: string;
  formValues: { [key: string]: any };
  setFormValues: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  element: {
    shortDesc?: string;
    longDesc?: {
      title: string;
      body: string;
    };
    name: string;
    [key: string]: any;
    cols: number;
  };
  isMultiple?: boolean;
}
const ObjectBox: React.FC<Component> = ({
  element,
  field,
  formValues,
  setFormValues,
  isMultiple = false,
}) => {
  const emptyObject = Object.keys(element.properties).reduce((acc, key) => {
    acc[key] = ""; // Initialize all fields with empty strings
    return acc;
  }, {} as EmptyObj);
  const [objectBoxFormData, setObjectBoxFormData] = useState(
    formValues[field] || {}
  );
  const [objectsBoxFormData, setObjectsBoxFormData] = useState(
    formValues[field] || [emptyObject]
  );
  const handleFieldChange = (index: number, updateFunc: any) => {
    // console.log("handleField === ",updateFunc(obj) );

    setObjectsBoxFormData((prevData: any) =>
      prevData.map((obj: any, i: number) =>
        i === index ? updateFunc(obj) : obj
      )
    );
  };

  const handleAddSection = () => {
    setObjectsBoxFormData((prevData: any) => [...prevData, emptyObject]);
  };

  const handleRemoveSection = (index: number) => {
    setObjectsBoxFormData((prevData: any) =>
      prevData.filter((_: any, i: number) => i !== index)
    );
  };

  useEffect(() => {
    if (isMultiple) {
      setFormValues((prevValues) => ({
        ...prevValues,
        [field]: objectsBoxFormData,
      }));
    }
  }, [objectsBoxFormData, field, setFormValues, isMultiple]);

  useEffect(() => {
    if (objectBoxFormData !== undefined && !isMultiple) {
      setFormValues((prevValues) => ({
        ...prevValues,
        [field]: objectBoxFormData,
      }));
    }
  }, [objectBoxFormData, field, setFormValues, isMultiple]);

  return (
    <div className={`lg:col-span-${element.cols || 12}`}>
      <div className="">
        {" "}
        <div className="flex justify-between">
          <div className="flex flex-col gap-1 mb-2">
            <div className="flex items-center gap-2">
              <h5>{element.name}</h5>
              {element.longDesc && (
                <RightDrawer
                  title={element.longDesc.title}
                  body={element.longDesc.body}
                />
              )}
            </div>
            {element.shortDesc && element.shortDesc !== "" && (
              <span className="text-sm">{element.shortDesc}</span>
            )}
          </div>
          {isMultiple && (
            <button
              type="button"
              className="btn bg-blue-700 text-white"
              onClick={handleAddSection}
            >
              Add More
            </button>
          )}
        </div>
        {isMultiple ? (
          <div className="flex flex-col gap-4 border p-2 mt-2">
            {objectsBoxFormData?.map((object: any, index: number) => (
              <div className={`lg:col-span-${element.cols || 12} relative`}>
                <div
                  className={`lg:col-span-${
                    element.cols || 12
                  } flex justify-between`}
                >
                  <h5>
                    {element.name} - {index + 1}
                  </h5>
                </div>
                <div className="flex justify-end absolute top-2 right-2">
                  {objectsBoxFormData?.length > 1 && (
                    <button
                      className="text-mb-red/90 hover:text-mb-red"
                      onClick={() => handleRemoveSection(index)}
                    >
                      {" "}
                      <svg
                        className="w-6 h-6 "
                        fill="currentColor"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="CancelIcon"
                      >
                        <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2m5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12z"></path>
                      </svg>
                    </button>
                  )}
                </div>
                <div
                  key={index}
                  className="grid grid-cols-1 gap-5 lg:grid-cols-12 mt-3"
                >
                  {Object.keys(element.properties).map((f, i) => {
                    if (element.properties[f].showInUi)
                      return (
                        <FormComponent
                          key={i}
                          field={f}
                          formValues={object}
                          setFormValues={(updatedField) =>
                            handleFieldChange(index, updatedField)
                          }
                          element={element.properties[f]}
                        />
                      );
                    return null;
                  })}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-1 gap-5 lg:grid-cols-12 mt-3">
            {Object.keys(element.properties).map((f, i) => {
              if (element.properties[f].showInUi)
                return (
                  <FormComponent
                    key={i}
                    field={f}
                    formValues={objectBoxFormData}
                    setFormValues={setObjectBoxFormData}
                    element={element.properties[f]}
                  />
                );
              return null;
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default ObjectBox;
