import React, { Suspense, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getContent,
  getMetaTemplateData,
  getMetaUiData,
  updateAssetInformation,
} from "helpers/mb-api_helper";
import BreadCrumb from "Common/BreadCrumb";
import { capitalizeFirstLetter } from "coustumFuntions/capitalFirstLetter";
import FormComponent from "./FormComponent";
import { ToastContainer } from "react-toastify";
import SnackBar from "Layout/SnackBar";
import { LucideProps } from "lucide-react";
import dynamicIconImports from "lucide-react/dynamicIconImports";

const fallback = <div style={{ background: "#ddd", width: 24, height: 24 }} />;

interface IconProps extends Omit<LucideProps, "ref"> {
  name: keyof typeof dynamicIconImports;
}

const Icon = ({ name, ...props }: IconProps) => {
  const LucideIcon = React.lazy(dynamicIconImports[name]);

  return (
    <Suspense fallback={fallback}>
      <LucideIcon {...props} />
    </Suspense>
  );
};

const EditContent = () => {
  const { type, id } = useParams<{ type: string; id: string }>();
  const [sections, setSections] = useState<any>([]);
  const [fields, setFields] = useState<any>({});
  const [formValues, setFormValues] = useState<any>({});
  const [formData, setFormData] = useState<any>({});
  const [activeTab, setActiveTab] = useState<number>(0);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState({
    show: false,
    message: "",
  });
  const [changeTheFormData, setChangeTheFormData] = useState<boolean>(false);

  const updateAssetInfo = async () => {
    try {
      await updateAssetInformation(formData.id, type, {
        ...formValues,
      });
      setShowSuccessSnackbar({
        show: true,
        message:
          formData?.v_status === "publish"
            ? "Successfully updated data"
            : "Successfully updated data save as draft.",
      });
      setFormData(formValues);
      setChangeTheFormData(false);
      setSubmitSuccess(true);
    } catch (err) {
      console.error("Error in updating content ", err);
    }
  };

  const handleSubmit = () => {
    updateAssetInfo();
  };

  const handlePublish = async () => {
    try {
      const response: any = await updateAssetInformation(formData.id, type, {
        v_status: "publish",
        v_published: true,
      });

      setShowSuccessSnackbar({
        show: true,
        message: "Data published successfully",
      });
      setFormData(response);
      setFormValues(response);
      setChangeTheFormData(false);
      setSubmitSuccess(false);
    } catch (err) {
      console.error("Error in updating content ", err);
    }
  };

  const resetForm = () => {
    setFormValues(formData);
    setChangeTheFormData(false);
  };

  const handleTabChange = async (eventKey: string | number) => {
    // Check if formData and formValues are not equal
    if (JSON.stringify(formData) !== JSON.stringify(formValues)) {
      await updateAssetInfo();
    }

    // Change the tab
    setActiveTab(Number(eventKey));
  };

  const handleCloseSnackBar = () => {
    setShowSuccessSnackbar({ show: false, message: "" });
  };

  // When any form data changes, set `changeTheFormData` to `true` so that the "Save as Draft" and "Publish" buttons can be enabled.
  if (
    !changeTheFormData &&
    JSON.stringify(formData) !== JSON.stringify(formValues)
  ) {
    setChangeTheFormData(true);
    if (submitSuccess) {
      setSubmitSuccess(false);
    }
  }

  useEffect(() => {
    const getContentInfo = async () => {
      try {
        const sectionRes: any = await getMetaUiData(type);
        const fieldRes: any = await getMetaTemplateData(type);
        const contentRes: any = await getContent(id, type);

        setSections(sectionRes.section);
        setFields(fieldRes.properties);
        setFormData({ ...contentRes });
        setFormValues({ ...contentRes });
      } catch (err) {
        console.error(err);
      }
    };
    if (type && id) getContentInfo();
  }, [type, id]);

  return (
    <React.Fragment>
      <BreadCrumb
        title={`Edit ${capitalizeFirstLetter(type)} - ${formData.title}`}
        pageTitle={`Manage ${capitalizeFirstLetter(type)}`}
      />
      <ToastContainer closeButton={false} limit={1} />
      <div className="grid grid-cols-1 xl:grid-cols-12 gap-x-5">
        <div className="xl:col-span-12">
          <div className="card">
            <div className="card-body">
              {/* <h6 className="mb-4 text-15">Edit</h6> */}
              <div>
                {sections?.length > 0 && (
                  <div>
                    <nav
                      className={`flex flex-wrap justify-start gap-header w-full text-sm font-medium text-center ${
                        sections?.length > 1 ? "border-b border-mb-blue" : ""
                      } px-2`}
                    >
                      {sections.length > 1 &&
                        sections.map((section: any, i: number) => (
                          <div
                            key={i}
                            // className={`flex gap-2 font-semibold cursor-pointer px-4 py-2 rounded-t-lg transition-all duration-300 ease-linear ${
                            //   activeTab === i
                            //     ? "border border-mb-blue border-b-white text-mb-blue"
                            //     : "text-slate-500 hover:text-mb-blue"
                            // }`}
                            className={`flex gap-2 px-4 py-2 cursor-pointer text-base transition-all duration-300 ease-linear rounded-t-md border
                              ${
                                activeTab === i
                                  ? "text-mb-blue border-mb-blue border-b-white"
                                  : "text-slate-500 border-transparent"
                              }
                              hover:text-mb-blue active:text-mb-blue dark:hover:text-mb-blue dark:active:text-mb-blue -mb-[1px]`}
                            onClick={() => handleTabChange(i)}
                          >
                            <Icon name={section.icon} />

                            <p>{section.name}</p>
                          </div>
                        ))}
                    </nav>

                    <div className="mt-5">
                      {sections.map((section: any, i: number) => (
                        <div
                          key={i}
                          className={`${activeTab === i ? "block" : "hidden"}`}
                        >
                          <div className="grid grid-cols-1 gap-5 lg:grid-cols-12">
                            <div className="lg:col-span-1 hidden"></div>
                            <div className="lg:col-span-2 hidden"></div>
                            <div className="lg:col-span-3 hidden"></div>
                            <div className="lg:col-span-4 hidden"></div>
                            <div className="lg:col-span-5 hidden"></div>
                            <div className="lg:col-span-6 hidden"></div>
                            <div className="lg:col-span-7 hidden"></div>
                            <div className="lg:col-span-8 hidden"></div>
                            <div className="lg:col-span-9 hidden"></div>
                            <div className="lg:col-span-10 hidden"></div>
                            <div className="lg:col-span-11 hidden"></div>
                            <div className="lg:col-span-12 hidden"></div>
                            {sections[activeTab]?.fields?.map(
                              (field: any, index: number) => {
                                if (fields[field] && fields[field].showInUi) {
                                  return (
                                    <FormComponent
                                      key={index}
                                      field={field}
                                      formValues={formValues}
                                      setFormValues={setFormValues}
                                      element={fields[field]}
                                      setChangeTheFormData={
                                        setChangeTheFormData
                                      }
                                    />
                                  );
                                }
                                return null;
                              }
                            )}
                          </div>

                          <div className="flex justify-end gap-2 mt-4">
                            <button
                              type="button"
                              className="btn bg-mb-red text-white hover:text-red-100 focus:text-red-100 active:text-red-100 dark:text-zink-700 dark:hover:text-mb-red/10 dark:focus:text-mb-red/10 dark:active:text-mb-red/10"
                              onClick={resetForm}
                            >
                              Clear
                            </button>

                            {formData?.v_status !== "publish" ? (
                              <>
                                <button
                                  onClick={handleSubmit}
                                  className={`${
                                    !changeTheFormData
                                      ? "btn bg-slate-300 text-white cursor-not-allowed"
                                      : "btn bg-yellow-500 text-white hover:bg-yellow-600 focus:bg-yellow-600"
                                  }`}
                                >
                                  Save As Draft
                                </button>

                                <button
                                  onClick={handlePublish}
                                  type="button"
                                  className={`${
                                    !submitSuccess
                                      ? "btn bg-slate-300 text-white cursor-not-allowed"
                                      : "btn bg-mb-green text-white hover:bg-mb-green focus:bg-mb-green"
                                  }`}
                                >
                                  Publish
                                </button>
                              </>
                            ) : (
                              <button
                                type="button"
                                onClick={handleSubmit}
                                className={`${
                                  !changeTheFormData
                                    ? "btn bg-slate-300 text-white cursor-not-allowed"
                                    : "btn bg-mb-green text-white hover:bg-mb-green focus:bg-mb-green"
                                }`}
                              >
                                Save
                              </button>
                            )}

                            {activeTab > 0 && (
                              <button
                                type="button"
                                className="btn bg-mb-blue text-white hover:bg-mb-blue focus:bg-mb-blue"
                                onClick={() => handleTabChange(activeTab - 1)}
                              >
                                Back
                              </button>
                            )}

                            {activeTab !== sections.length - 1 && (
                              <button
                                type="button"
                                className="btn bg-mb-blue text-white hover:bg-mb-blue focus:bg-mb-blue"
                                onClick={() => handleTabChange(activeTab + 1)}
                              >
                                Next
                              </button>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {showSuccessSnackbar.show && (
            <SnackBar
              message={showSuccessSnackbar.message}
              onClose={handleCloseSnackBar}
            />
          )}
        </div>
        {/* <div className="xl:col-span-3">
          <div className="card sticky top-[calc(theme('spacing.header')_*_1.3)]">
            <div className="card-body bg-white">
              <p>Preview Section</p>
            </div>
          </div>
        </div> */}
      </div>
    </React.Fragment>
  );
};

export default EditContent;
