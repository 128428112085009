// dashboard
import Ecommerce from "pages/Dashboards/Ecommerce";
import Login from "pages/Authentication/Login";
import Logout from "pages/Authentication/LogOut";
import ManagementPage from "pages/ContentManagement/ManagementPage";
import EditContent from "pages/ContentManagement/EditContent";
import ListPlatform from "pages/PlatformManagement/ListPlatform";
import ListView from "pages/PlatformManagement/ListView";
import ListViews from "pages/PlatformManagement/ListViews";
import ListMenus from "pages/PlatformManagement/ListMenus";
import RailsAddition from "pages/PlatformManagement/railsAddition";
import MenuSection from "pages/PlatformManagement/MenuSection";
import PlatformMenuIdEdit from "pages/PlatformManagement/PlatformMenuIdEdit";

interface RouteObject {
  path: string;
  component: React.ComponentType<any>; // Use React.ComponentType to specify the type of the component
  exact?: boolean;
}

const authProtectedRoutes: Array<RouteObject> = [
  { path: "/", component: Ecommerce },
  { path: "/dashboard", component: Ecommerce },
  { path: "/dashboard/:type", component: Ecommerce },
  { path: "/reports", component: Ecommerce },
  { path: "/reports/:type", component: Ecommerce },
  { path: "/view/platforms", component: ListPlatform },
  { path: "/view/menu", component: ListPlatform },
  { path: "/manage/:type", component: ManagementPage },
  { path: "/manage/:type/edit/:id", component: EditContent },
  { path: "/platforms", component: ListPlatform },
  { path: "/views", component:  ListViews},
  { path: "/menus", component:  ListMenus},
  { path: "/platforms/views/:id", component: ListView },
  { path: "/platforms/views/:platformId/:viewId", component: RailsAddition },
  { path: "/platforms/menus/:id", component: MenuSection },
  { path: "/platforms/menus/:id/:menuId", component: PlatformMenuIdEdit },
];

const publicRoutes = [
  // authentication
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
];

export { authProtectedRoutes, publicRoutes };
