import RightDrawer from "Layout/RightDrawer";
import React, { useEffect, useState } from "react";

interface DateTimePickerProps {
  field: string;
  formValues: { [key: string]: any };
  setFormValues: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  element: {
    shortDesc?: string;
    longDesc?: {
      title: string;
      body: string;
    };
    name: string;
    cols: number;
  };
}

const DateTimePicker: React.FC<DateTimePickerProps> = ({
  element,
  field,
  formValues,
  setFormValues,
}) => {
  const [date, setDate] = useState<string>("");
  const storedValue = formValues[field];

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDate(e.target.value);
    const inputDate = new Date(e.target.value);
    const timestamp = inputDate.getTime();
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: timestamp,
    }));
  };

  const utcDate = date ? new Date(date).toUTCString() : "";

  useEffect(() => {
    if (storedValue) {
      // Convert stored timestamp to ISO string for datetime-local input
      const formattedDate = new Date(storedValue).toISOString().slice(0, 16);
      setDate(formattedDate);
    }
  }, [storedValue]);

  return (
    <div className={`lg:col-span-${element.cols || 6}`}>
      <div className="flex flex-col space-y-4">
        <div>
          <div className="flex flex-col gap-1 mb-2">
            <div className="flex items-center gap-2">
              <label className="inline-block text-base font-medium">
                {element.name}
              </label>
              {element.longDesc && (
                <RightDrawer
                  title={element.longDesc.title}
                  body={element.longDesc.body}
                />
              )}
            </div>
            {element.shortDesc && element.shortDesc !== "" && (
              <span className="text-sm">{element.shortDesc}</span>
            )}
          </div>
          <input
            type="datetime-local"
            value={date}
            onChange={handleDateChange}
            className="mt-1 block w-full p-2 border rounded-md shadow-sm"
          />
          {date && <p className="mt-2 text-gray-600">{utcDate}</p>}
        </div>
      </div>
    </div>
  );
};

export default DateTimePicker;
