import React, { useEffect, useState } from "react";
import countries from "lib/countries";
import languages from "lib/languages";
import { getPlatforms } from "helpers/mb-api_helper";
import Select from "react-select";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import i18n from "i18n";

interface Component {
  id: number;
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
}
interface ValObject {
  [key: string]: string; // Adjust according to the fields you have
}
interface I18nEntry {
  lang: String;
  vals: ValObject;
}
const BasicComponent: React.FC<Component> = ({ id, formData, setFormData }) => {
  let selectProperties = createSelector(
    (state: any) => state.User,
    (user) => ({
      meta: user.meta,
    })
  );
  let { meta } = useSelector(selectProperties);

  let statusOptions = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
  ];

  let cohortOptions = [
    { value: "cohort1", label: "Cohort 1" },
    { value: "cohort2", label: "Cohort 2" },
  ];

  let layoutOptions = [
    { value: "layout1", label: "Layout 1" },
    { value: "layout2", label: "Layout 2" },
  ];

  let singleLayoutOptions = [
    { value: "basic1", label: "Basic 1" },
    { value: "Advanced", label: "Advanced 2" },
  ];
  let [activeTab, setActiveTab] = useState<number>(0);

  let [startDate, setStartDate] = useState<any>(
    formData[id]?.startDate ? formData[id].startDate : null
  );
  let [endDate, setEndDate] = useState<any>(
    formData[id]?.endDate ? formData[id].endDate : null
  );

  let [title, setTitle] = useState<any>(
    formData[id]?.title ? formData[id].title : ""
  );
  let [langI18n, setLangI18n] = useState<any>(
    formData[id]?.i18n ? formData[id].i18n : []
  );

  let [description, setDescription] = useState<any>(
    formData[id]?.description ? formData[id].description : ""
  );

  let [langOptions, setLangOptions] = useState<any>(
    languages
      ? Object.keys(languages).map((v) => {
          return { value: v, label: languages[v].name };
        })
      : []
  );
  let [selectedLanguages, setSelectedLanguages] = useState<any>(
    formData && formData[id] && formData[id]?.language.length > 0
      ? formData[id].language.map((v: any) => ({
          value: v,
          label: languages[v].name,
        }))
      : []
  );

  let [status, setStatus] = useState<any>(
    formData[id]?.status ? formData[id].status : null
  );
  let [selectedCohort, setSelectedCohort] = useState<any>(
    formData && formData[id] && formData[id]?.cohorts.length > 0
      ? formData[id].cohorts.map((v: any) => ({ value: v, label: v }))
      : []
  );

  let [platformOptions, setPlatformOptions] = useState<any>([]);
  let [selectedPlatforms, setSelectedPlatforms] = useState<any>(
    formData && formData[id] && formData[id]?.platforms.length > 0
      ? formData[id].platforms.map((v: any) => ({ value: v, label: v }))
      : []
  );

  let [allowedCountriesOptions, setAllowedCountriesOptions] = useState<any>(
    countries
      ? Object.keys(countries).map((v) => {
          return { value: v, label: countries[v as keyof typeof countries] };
        })
      : []
  );
  let [selectedAllowedCountries, setSelectedAllowedCountries] = useState<any>(
    formData && formData[id] && formData[id]?.allowedCountries.length > 0
      ? formData[id].allowedCountries.map((v: any) => ({
          value: v,
          label: countries[v as keyof typeof countries],
        }))
      : []
  );

  let [isAvodTray, setIsAvoidTray] = useState<any>(
    formData[id]?.isAvodTray ? formData[id].isAvodTray : false
  );
  let [showRailTitle, setShowRailTitle] = useState<any>(
    formData[id]?.showRailTitle ? formData[id].showRailTitle : false
  );
  let [isPremiumTray, setIsPremiumTray] = useState<any>(
    formData[id]?.isPremiumTray ? formData[id].isPremiumTray : false
  );
  let [showMoreButton, setShowMoreButton] = useState<any>(
    formData[id]?.showMoreButton ? formData[id].showMoreButton : false
  );
  let [selectButtons, setSelectButtons] = useState<any>(
    formData[id]?.moreLayout ? formData[id].moreLayout : null
  );
  let [selectLayout, setSelectLayout] = useState<any>(
    formData[id]?.layout ? formData[id].layout : null
  );

  useEffect(() => {
    let newformData = [...formData];
    newformData[id] = {
      ...formData[id],
      railId: formData[id].id,
      // apiPath: `${formData[id].apiPathRef}?filters=type:movie;genres:action;language:${selectedLanguages}&sort=telecastDate:desc"`,
      title: title,
      i18n: langI18n,
      description: description,
      language: selectedLanguages.map((lang: any) => lang.value),
      status: status ? status : null,
      cohorts: selectedCohort.map((c: any) => c.value),
      platforms: selectedPlatforms.map((plat: any) => plat.value),
      allowedCountries: selectedAllowedCountries.map(
        (allowCountry: any) => allowCountry.value
      ),
      isAvodTray: isAvodTray,
      showRailTitle: showRailTitle,
      isPremiumTray: isPremiumTray,
      showMoreButton: showMoreButton,
      moreLayout: showMoreButton ? selectButtons : null,
      layout: selectLayout ? selectLayout : null,
      startDate: startDate ? startDate : null,
      endDate: endDate ? endDate : null,
      railInstanceId:
        formData[id] && formData[id].railInstanceId
          ? formData[id].railInstanceId
          : null,
    };
    console.log("newformData", newformData);
    setFormData(newformData);
  }, [
    id,
    langI18n,
    selectedLanguages,
    startDate,
    endDate,
    showMoreButton,
    title,
    description,
    status,
    selectedCohort,
    selectedPlatforms,
    selectedAllowedCountries,
    isAvodTray,
    showRailTitle,
    isPremiumTray,
    selectButtons,
    selectLayout,
  ]);

  let handleChange = (selected: any) => {
    setSelectedLanguages(selected);
  };
  let handleChangeStartDate = (selected: any) => {
    let gmtStartDate = new Date(selected[0]).toISOString();
    setStartDate(gmtStartDate);
  };
  let handleChangeEndDate = (selected: any) => {
    let gmtEndDate = new Date(selected[0]).toISOString();
    setEndDate(gmtEndDate);
  };
  let handleChangeCohort = (selected: any) => {
    setSelectedCohort(selected);
  };
  let handleChangePlatforms = (selected: any) => {
    setSelectedPlatforms(selected);
  };
  let handleChangeAllowedCountries = (selected: any) => {
    setSelectedAllowedCountries(selected);
  };
  let handleChangeLayout = (selected: any) => {
    const selectedData = selected.value;
    setSelectLayout(selectedData);
  };
  let handleChangeStatus = (selected: any) => {
    const selectedData = selected.value;
    setStatus(selectedData);
  };
  let handleChangeMoreLayout = (selected: any) => {
    setSelectButtons(selected.value);
  };
  let handleDescriptionChange = (event: any) => {
    const newDescription = event.target.value;
    setDescription(newDescription);
  };
  let handleChangeTitle = (event: any) => {
    const newTitle = event.target.value;
    const activeLang = meta.languages[activeTab];
    // Ensure i18n exists and has the current language
    const updatedI18n: I18nEntry[] = formData[id]?.i18n || [];

    // Find or create the entry for the active language
    const updatedEntries = updatedI18n.map((entry: I18nEntry) => {
      if (entry.lang === activeLang) {
        // Ensure vals object contains the necessary field
        return {
          ...entry,
          vals: {
            ...entry.vals,
            title: newTitle,
          },
        };
      }
      return entry;
    });

    // If no entry for the active language exists, add one
    const hasActiveLang = updatedEntries.some(
      (entry: I18nEntry) => entry.lang === activeLang
    );

    if (!hasActiveLang) {
      updatedEntries.push({
        lang: activeLang,
        vals: {
          title: newTitle,
        },
      });
    }
    console.log("newTitle", newTitle);
    console.log("updatedEntries", updatedEntries);
    if (meta.lang === activeLang) {
      setTitle(newTitle);
    }
    setLangI18n(updatedEntries);
  };
  let handleChangeShowRail = (event: any) => {
    setShowRailTitle(event.target.checked);
  };
  let handleChangeIsTray = (event: any) => {
    setIsAvoidTray(event.target.checked);
  };
  let handleChangePremium = (event: any) => {
    setIsPremiumTray(event.target.checked);
  };
  let handleChangeShowMoreButton = (event: any) => {
    setShowMoreButton(event.target.checked);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const response: any = await getPlatforms(); // Await the API call to fetch platforms
        let platforms = response.list;
        setPlatformOptions(
          platforms
            ? Object.keys(platforms).map((v) => {
                return { value: platforms[v].id, label: platforms[v].name };
              })
            : []
        );
      } catch (e) {
        console.log(e);
      }
    };
    getData();
  }, []);

  return (
    <div className="grid lg:grid-cols-2 gap-4 justify-between ml-5 mr-5 mb-5">
      <div className="col-span-2">
        <label
          htmlFor="title"
          className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize"
        >
          Title
        </label>
        <div className="flex gap-3 flex-wrap">
          {meta.languages.map((lang: any, i: number) => (
            <p
              className={`p-2 ${
                activeTab === i
                  ? "underline text-mb-blue font-bold"
                  : "text-black"
              } `}
              onClick={() => setActiveTab(i)}
            >
              {languages[lang].name}
            </p>
          ))}
        </div>
        <div>
          <input
            type="text"
            id="title"
            value={
              formData[id]?.i18n
                ? formData[id]?.i18n.find(
                    (langObj: I18nEntry) =>
                      langObj.lang === meta.languages[activeTab]
                  )?.vals["title"] || ""
                : ""
            }
            onChange={handleChangeTitle}
            // name={formData[id]?.title}
            className="form-input flex-1 placeholder:capitalize"
            placeholder="Enter Title"
          />
        </div>
      </div>
      <div className="col-span-2">
        <label
          htmlFor="description"
          className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize"
        >
          Description
        </label>
        <input
          id="description"
          className="form-input flex-1 placeholder:capitalize"
          value={formData[id]?.description}
          onChange={handleDescriptionChange}
          placeholder="Enter Description"
        />
      </div>
      <div className="flex flex-col sm:flex-row">
        <label className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize">
          Languages
        </label>
        <Select
          isMulti
          value={selectedLanguages}
          onChange={handleChange}
          options={langOptions}
          placeholder="Select options"
          className="capitalize"
        />
      </div>
      <div className="flex flex-col sm:flex-row">
        <label className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize">
          Platforms
        </label>
        <Select
          isMulti
          value={selectedPlatforms}
          onChange={handleChangePlatforms}
          options={platformOptions}
          placeholder="Select options"
          className="capitalize"
        />
      </div>
      <div className="flex flex-col sm:flex-row">
        <label className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize">
          Layout
        </label>
        <Select
          value={
            formData && formData[id] && formData[id].layout
              ? { value: formData[id].layout, label: formData[id].layout }
              : null
          }
          onChange={handleChangeLayout}
          options={singleLayoutOptions}
          placeholder="Select options"
          className="capitalize"
        />
      </div>
      <div className="flex flex-col sm:flex-row">
        <label className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize">
          Allowed Countries
        </label>
        <Select
          isMulti
          value={selectedAllowedCountries}
          onChange={handleChangeAllowedCountries}
          options={allowedCountriesOptions}
          placeholder="Select options"
          className="capitalize"
        />
      </div>
      <div className="flex flex-col sm:flex-row">
        <label className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize">
          Status:
        </label>
        <Select
          value={
            formData && formData[id] && formData[id].status
              ? { value: formData[id].status, label: formData[id].status }
              : null
          }
          onChange={handleChangeStatus}
          options={statusOptions}
          placeholder="Select options"
          className="capitalize"
        />
      </div>
      <div className="flex flex-col sm:flex-row">
        <label className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize">
          Cohorts
        </label>
        <Select
          isMulti
          value={selectedCohort}
          onChange={handleChangeCohort}
          options={cohortOptions}
          placeholder="Select options"
          className="capitalize"
        />
      </div>
      <div className="flex flex-col sm:flex-row">
        <label className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize">
          Start Date
        </label>
        <Flatpickr
          data-enable-time
          options={{
            enableTime: true,
            dateFormat: "Y-m-d H:i",
          }}
          value={startDate}
          className="form-input w-auto"
          onChange={handleChangeStartDate}
        />
      </div>
      <div className="flex flex-col sm:flex-row">
        <label className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize">
          End Date
        </label>
        <Flatpickr
          data-enable-time
          options={{
            enableTime: true,
            dateFormat: "Y-m-d H:i",
          }}
          value={endDate}
          className="form-input w-auto"
          onChange={handleChangeEndDate}
        />
      </div>
      <div className="flex flex-col sm:flex-row">
        <label
          htmlFor="showRailTitle"
          className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize"
        >
          showRailTitle
        </label>
        <input
          id="showRailTitle"
          className="form-checkbox"
          type="checkbox"
          checked={formData[id]?.showRailTitle || false}
          onChange={handleChangeShowRail}
        />
      </div>

      <div className="flex flex-col sm:flex-row">
        <label
          htmlFor="isAvodTray"
          className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize"
        >
          isAvodTray
        </label>
        <input
          id="isAvodTray"
          className="form-checkbox"
          type="checkbox"
          checked={formData[id]?.isAvodTray || false}
          onChange={handleChangeIsTray}
        />
      </div>

      <div className="flex flex-col sm:flex-row">
        <label
          htmlFor="isPremiumTray"
          className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize"
        >
          isPremiumTray
        </label>
        <input
          id="isPremiumTray"
          className="form-checkbox"
          type="checkbox"
          checked={formData[id]?.isPremiumTray || false}
          onChange={handleChangePremium}
        />
      </div>

      <div className="flex gap-10 sm:flex-row">
        <div className="flex justify-between gap-6">
          <label className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize">
            ShowMoreButton
          </label>
          <input
            className="form-checkbox"
            type="checkbox"
            checked={formData[id]?.showMoreButton || false}
            onChange={handleChangeShowMoreButton}
          />
        </div>
        <div>
          {formData[id]?.showMoreButton && (
            <div className="flex flex-col sm:flex-row justify-between">
              <label className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize">
                More Layout
              </label>
              <div>
                <Select
                  value={
                    formData && formData[id] && formData[id].moreLayout
                      ? {
                          value: formData[id].moreLayout,
                          label: formData[id].moreLayout,
                        }
                      : null
                  }
                  onChange={handleChangeMoreLayout}
                  options={layoutOptions}
                  placeholder="Select options"
                  className="capitalize"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BasicComponent;
