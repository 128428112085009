import React from "react";

interface Component {
  element: {
    cols?: number;
  };
}

const EmptyBox: React.FC<Component> = ({ element }) => {
  return (
    <div className={`hidden emptyBox lg:block lg:col-span-${element.cols || 12}`}></div>
  );
};

export default EmptyBox;
