import React, { useEffect, useState} from "react";
import { useParams, useNavigate } from "react-router-dom";
import BreadCrumb from 'Common/BreadCrumb';
import { createRailInstance, updateRailInstance, getViewById, getRails, updateDraftView, getDraftViewById, getRailInstanceById, createDraftView, publishView} from "helpers/mb-api_helper";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import Collapse from "Common/Components/Collapse/Collapse";
import Modal from "Common/Components/Modal";
import RailsComponent from "components/railsComponent/RailsComponent";
import { getObjectWithKey } from "helpers/utility";
import { ReactSortable } from "react-sortablejs"

// Icons
import { Plus, ChevronDown, ChevronUp } from 'lucide-react';
import { Promise } from "bluebird";
import { join } from "path";

const RailsAddition: React.FC = () => {
    let selectProperties = createSelector(
        (state: any) => state.User,
        (user) => ({
          meta: user.meta,
        })
    );
    let { meta } = useSelector(selectProperties);
    let { platformId, viewId } = useParams()

    let navigate = useNavigate()
    let [show, setShow] = useState<boolean>(false);
    let [active, setActive] = useState<any>(-1);
    let [isPublished, setIsPublished] = useState<boolean>(false)
    let [apiSuccess, setApiSuccess] = useState<boolean>(false)
    let [undo, setUndo] = useState<boolean>(false)
    // let [published, setPublished] = useState<boolean>(false)
    let [allRailsData, setAllRailsData] = useState<any>([])
    let [formData, setFormData] = useState<any>([])
    let [extViewData, setExtViewData] = useState<any>([])
    let [parentViewId, setParentViewId] = useState<any>('')

    //save draft copy
    let handleSaveRails = async () => {
        if (formData) {
            let finalFormData = [...formData]
            formData.map( async (fd:any, i:number) => {
                if(fd.placeholderData.length>0){
                    let railBody = {
                        "assets": fd.placeholderData
                    }
                
                    let railResponse: any
                    if(fd.railInstanceId){
                        railResponse = await updateRailInstance(fd.railInstanceId,railBody); 
                    }else{
                        railResponse = await createRailInstance(railBody); 
                    }
                    // console.log('railResponse',railResponse)
                    let railInstanceId = railResponse.id
                    finalFormData[i] =  {
                        ...fd,
                        railInstanceId: railInstanceId,
                    };
                    setFormData(finalFormData)
                }
            });

            let newFinalformData = finalFormData.map((rail) => {
                let { placeholderData, uiElement, ...rest } = rail
                return rest
            })
            // console.log('newFinalformData',newFinalformData)
            let body = {
                "rails": {
                    "count": newFinalformData.length,
                    "list": newFinalformData
                }
            }
            // console.log('viewId',body)
            try {
                const draftResponse: any = await updateDraftView(viewId,body); 
                if (draftResponse) {
                    setApiSuccess(true)
                    setUndo(true)
                }
            } catch (error) {
                setApiSuccess(false)
                setUndo(false)
            }
        }
    }

    //save draft copy to Live
    const handlePublishViewRails = async () => {
        try {
            const response: any = await publishView(viewId); 
            setIsPublished(true)
        } catch (error) {
            console.log(error)
        }
    }
    // add rail to view
    const handleAdd = (id:any) => {
        let data = allRailsData.filter((item:any) => item.id === id)
        let finalData = data.map((d:any, i:number) => {
            return {
                ...d,
                title: '',
                i18n: [],
                description: '',
                isAvodTray: false,
                showRailTitle: false,
                isPremiumTray: false,
                showMoreButton: false,
                moreLayout: null,
                layout: null,
                railInstanceId:null,
                startDate:null,
                endDate:null,
                status: null,
                language: [],
                cohorts: [],
                placeholderData:[],
                platforms:[],
                allowedCountries:[]
            };
        });
        setFormData((prev:any) => [...prev, ...finalData])
        setApiSuccess(false)
        setUndo(false)
        setShow(false)
        setActive(formData.length)
    }
    const handleEdit = () => {
        navigate(`/platforms/views/${platformId}/${viewId}`)
        setIsPublished(false)
        setApiSuccess(false)
        setUndo(false)
        setShow(false)
    }
    const getFilterUIData = (filterData: any) => {
        let filterString = `${filterData.filter?.label || ''} ${filterData.operator?.value || ''}`;
        if (filterData.uiElement == 'multiSelect') {
            filterString += ' ' + filterData.value?.map((item: any) => item.label).join(', ');
        } else {
            filterString += ' ' + filterData.value?.value;
        }
        return filterString
    }
    
    useEffect(() => {
        const getData = async () => {
            // Await the API call to fetch rails
            const railResponse: any = await getRails(); 
            setAllRailsData(railResponse.list)
            let railList = await getObjectWithKey(railResponse.list); 

            try {
                // Await the API call to fetch draft view by id
                const draftResponse: any = await getDraftViewById(viewId);
                if(draftResponse.rails){
                    let draftRailData = draftResponse.rails.list
                    let finalDraftRailData : any=[]
                    finalDraftRailData = await Promise.map(draftRailData,async(draftRail:any, i:number)=>{
                        let placeholderData : any=[]
                        if(draftRail.railInstanceId){
                            // console.log('railInstanceId------',draftRail.railInstanceId)
                            // Await the API call to fetch rail instance by id
                            const railInstanceData: any = await getRailInstanceById(draftRail.railInstanceId); 
                            placeholderData = railInstanceData.assets
                            return  {
                                ...draftRail,
                                id: draftRail.id,
                                name: railList[draftRail.id]?.name,
                                uiElement: railList[draftRail.id].uiElement,
                                placeholders: railList[draftRail.id].placeholders,
                                placeholderData: placeholderData,
                            };
                        }else{
                            return  {
                                ...draftRail,
                                id: draftRail.id,
                                name: railList[draftRail.id]?.name,
                                uiElement: railList[draftRail.id].uiElement,
                                placeholders: railList[draftRail.id].placeholders,
                                placeholderData: placeholderData,
                            };
                        }
                    },{concurrency:1})
                
                    // console.log('finalDraftRailData------',finalDraftRailData)
                    setFormData([...finalDraftRailData])   
                }
                         
            } catch (error: unknown) {
                // console.log('e.response',(error instanceof Error) )
                if((error instanceof Error)){
                    // Await the API call to create draft view by id
                    const draftResponse: any = await createDraftView(viewId); 
                    let finalDraftRailData : any=[]

                    if(draftResponse.rails){
                        let draftRailData = draftResponse.rails.list
                        finalDraftRailData = await Promise.map(draftRailData,async(draftRail:any, i:number)=>{
                            let placeholderData : any=[]
                            if(draftRail.railInstanceId){
                                // console.log('railInstanceId',draftRail.railInstanceId)
                                // Await the API call to fetch rail instance by id
                                const railInstanceData: any = await getRailInstanceById(draftRail.railInstanceId); 
                                placeholderData = railInstanceData.assets
                                return  {
                                    ...draftRail,
                                    id: draftRail.id,
                                    name: railList[draftRail.id]?.name,
                                    uiElement: railList[draftRail.id]?.uiElement,
                                    placeholders: railList[draftRail.id]?.placeholders,
                                    placeholderData: placeholderData,
                                };
                            }else{
                                return  {
                                    ...draftRail,
                                    id: draftRail.id,
                                    name: railList[draftRail.id]?.name,
                                    uiElement: railList[draftRail.id]?.uiElement,
                                    placeholders: railList[draftRail.id]?.placeholders,
                                    placeholderData: placeholderData,
                                };
                            }
                        },{concurrency:1})
                        
                        // console.log('finalDraftRailData',finalDraftRailData)
                        setFormData(finalDraftRailData)   
                    }
                }
            }
        }
        
        const getExtViewData = async () => {
            // Await the API call to fetch view by id
            const viewResponse: any = await getViewById(viewId); 
            if(viewResponse.v_parent){
                // Await the API call to fetch parent view by id
                const extViewResponse: any = await getViewById(viewResponse.v_parent); 
                setParentViewId(extViewResponse.id)
                if(extViewResponse.rails){
                    let draftRailData = extViewResponse.rails.list
                    let finalDraftRailData : any=[]
                    finalDraftRailData = await Promise.map(draftRailData,async(draftRail:any, i:number)=>{
                        let placeholderData : any=[]
                        if(draftRail.railInstanceId){
                            // console.log('extended railInstanceId------',draftRail.railInstanceId)
                            // Await the API call to fetch rail instance by id
                            const railInstanceData: any = await getRailInstanceById(draftRail.railInstanceId); 
                            placeholderData = railInstanceData.assets
                            return  {
                                ...draftRail,
                                placeholderData: placeholderData,
                            };
                        }else{
                            return  {
                                ...draftRail
                            };
                        }
                    },{concurrency:1})
                    setExtViewData(finalDraftRailData)
                }
            }
        }
        getData()
        getExtViewData()
    }, [])


    useEffect(()=>{
        console.log('formData',formData)
    },[formData])
    
    return (
        <React.Fragment>
            <BreadCrumb title={`Add Rails`} pageTitle='' />
            <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
                <div className="xl:col-span-12">
                    <div className="card" id="usersTable">
                        <div className="card-body">
                            <div className="flex items-center justify-between">
                                <div className="relative xl:col-span-2">
                                    <h5 className="text-16">{viewId}</h5>
                                </div>
                                <div className="flex gap-2">
                                    {isPublished ? (
                                        <><button type="button" className="text-white btn bg-green-500 border-green-500 hover:text-white hover:bg-green-600 hover:border-green-600 focus:text-white focus:bg-green-600 focus:border-green-600 focus:ring focus:ring-custom-100 active:text-white active:bg-green-600 active:border-green-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                        Published
                                    </button>
                                    {/* <button type="button" className="text-white btn bg-orange-500 border-orange-500 hover:text-white hover:bg-orange-600 hover:border-orange-600 focus:text-white focus:bg-orange-600 focus:border-orange-600 focus:ring focus:ring-custom-100 active:text-white active:bg-orange-600 active:border-orange-600 active:ring active:ring-custom-100 dark:ring-custom-400/20" onClick={handleEdit}>
                                        Edit
                                    </button> */}
                                    </>) : ''}
                                    {!isPublished && apiSuccess ? (
                                        <button type="button" className="text-white btn bg-orange-500 border-orange-500 hover:text-white hover:bg-orange-600 hover:border-orange-600 focus:text-white focus:bg-orange-600 focus:border-orange-600 focus:ring focus:ring-custom-100 active:text-white active:bg-orange-600 active:border-orange-600 active:ring active:ring-custom-100 dark:ring-custom-400/20" onClick={handlePublishViewRails} >
                                        Publish
                                    </button>) : ''}
                                    {!isPublished && !undo && formData && formData.length > 0 ? (
                                        <button className="text-white btn bg-green-500 border-green-500 hover:text-white hover:bg-green-600 hover:border-green-600 focus:text-white focus:bg-green-600 focus:border-green-600 focus:ring focus:ring-custom-100 active:text-white active:bg-green-600 active:border-green-600 active:ring active:ring-custom-100 dark:ring-custom-400/20" onClick={() => handleSaveRails()} >
                                            Save
                                        </button>
                                    ) : ''}
                                    {/* {!isPublished && undo && 
                                        <button className="text-white bg-red-500 border-red-500 btn hover:text-white hover:bg-red-600 hover:border-red-600 focus:text-white focus:bg-red-600 focus:border-red-600 focus:ring focus:ring-red-100 active:text-white active:bg-red-600 active:border-red-600 active:ring active:ring-red-100 dark:ring-custom-400/20" onClick={() => setUndo(false)}>
                                            Cancel
                                        </button>
                                    } */}
                                    {/* {!isPublished && !undo && */}
                                        <button type="button" onClick={() => setShow(true)} className="text-white btn bg-mb-blue border-mb-blue hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                            <Plus className="inline-block size-4" /> 
                                            Add Rail
                                        </button>
                                    {/* } */}
                                </div>
                            </div>
                        </div>
                        
                        <div className="card-body">
                        {/* current view rails */}
                        { formData && formData?.length > 0 && 
                            <ReactSortable list={formData} setList={setFormData}>
                                {formData.map((item: any, idx:any) => {
                                    return (
                                        <div className='mb-2.5 cursor-grab' key={idx}>
                                            <RailsComponent 
                                                id={idx}
                                                railData={item} 
                                                formData={formData}
                                                setFormData={setFormData}
                                                setUndo={setUndo}
                                                setApiSuccess={setApiSuccess}
                                                active={active}
                                                setActive={setActive}
                                            />
                                        </div>
                                    )
                                })}
                            </ReactSortable>
                        }
                            
                        {/* parents view rails */}
                        {/* { console.log('extViewData',extViewData) } */}
                        {extViewData.map((item: any, idx:any) => {
                            return (
                                <div className='mb-2.5 cursor-grab' key={idx}>
                                    <Collapse>
                                        <Collapse.Trigger className="flex p-3 mb-2 w-full text-white collapsible-header group/item btn bg-slate-500 border-slate-500 hover:text-white hover:bg-slate-600 hover:border-slate-600 focus:text-white focus:bg-slate-600 focus:border-slate-600 focus:ring focus:ring-custom-100 active:text-white active:bg-slate-600 active:border-slate-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                            <div className="rtl:mr-2 gap-5 shrink-0">
                                                <ChevronDown className="hidden size-4 group-[.show]/item:inline-block"></ChevronDown>
                                                <ChevronUp className="inline-block size-4 group-[.show]/item:hidden"></ChevronUp>
                                            </div>
                                            {parentViewId} -- {item.title}
                                        </Collapse.Trigger>
                                        <Collapse.Content className="mt-2 mb-0 collapsible-content card">
                                            <div className="card-body">
                                                <div className="grid lg:grid-cols-4 gap-4 justify-between ml-5 mr-5 mb-5">
                                                    <div>
                                                        <label
                                                            className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize font-bold"
                                                        >
                                                            Title:
                                                        </label>
                                                        {item.title}
                                                    </div>
                                                    <div>
                                                        <label
                                                            className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize font-bold"
                                                        >
                                                            Description:
                                                        </label>
                                                        {item.description}
                                                    </div>
                                                    <div>
                                                        <label
                                                            className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize font-bold"
                                                        >
                                                            Status: 
                                                        </label>
                                                        {item.status}
                                                    </div>

                                                    <div>
                                                        <label
                                                            className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize font-bold"
                                                        >
                                                            Layout: 
                                                        </label>
                                                        {item.layout}
                                                    </div>
                                                    
                                                    <div>
                                                        <label
                                                            className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize font-bold"
                                                        >
                                                            Languages: 
                                                        </label>
                                                        {item.language && item.language.length > 0 && 
                                                            item.language.join(', ')
                                                        }
                                                    </div>
                                                    <div>
                                                        <label
                                                            className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize font-bold"
                                                        >
                                                            Platforms: 
                                                        </label>
                                                        {item.platforms && item.platforms.length > 0 && 
                                                            item.platforms.join(', ')
                                                        }
                                                    </div>
                                                    <div>
                                                        <label
                                                            className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize font-bold"
                                                        >
                                                            Allowed Countries: 
                                                        </label>
                                                        {item.platforms && item.allowedCountries.length > 0 && 
                                                            item.allowedCountries.join(', ')
                                                        }
                                                    </div>
                                                    <div>
                                                        <label
                                                            className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize font-bold"
                                                        >
                                                            Cohorts: 
                                                        </label>
                                                        {item.cohorts && item.cohorts.length > 0 && 
                                                            item.cohorts.join(', ')
                                                        }
                                                    </div>
                                                    <div>
                                                        <label
                                                            className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize font-bold"
                                                        >
                                                            More Layout: 
                                                        </label>
                                                        {item.moreLayout}
                                                    </div>
                                                    <div>
                                                        <label
                                                            className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize font-bold"
                                                        >
                                                            Show Rail Title: 
                                                        </label>
                                                        {item.showRailTitle?'Yes':'No'}
                                                    </div>
                                                    <div>
                                                        <label
                                                            className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize font-bold"
                                                        >
                                                            Premium Tray: 
                                                        </label>
                                                        {item.isPremiumTray?'Yes':'No'}
                                                    </div>
                                                    <div>
                                                        <label
                                                            className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize font-bold"
                                                        >
                                                            Show More Button: 
                                                        </label>
                                                        {item.showMoreButton?'Yes':'No'}
                                                    </div>
                                                    <div>
                                                        <label
                                                            className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize font-bold"
                                                        >
                                                            AVOD Tray: 
                                                        </label>
                                                        {item.isAvodTray?'Yes':'No'}
                                                    </div>
                                                    <div>
                                                        <label
                                                            className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize font-bold"
                                                        >
                                                            Start Date: 
                                                        </label>
                                                        {item.startDate}
                                                    </div>
                                                    <div>
                                                        <label
                                                            className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize font-bold"
                                                        >
                                                            End Date: 
                                                        </label>
                                                        {item.endDate}
                                                    </div>
                                                   
                                                    
                                                </div>
                                                {item.sortData && item.sortValues &&
                                                    <>
                                                    <div className="grid lg:grid-cols-2 gap-4 justify-between ml-5 mr-5 mb-5">
                                                        <div>
                                                            <label
                                                                className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize font-bold"
                                                            >
                                                                Sort Options: 
                                                            </label>
                                                            {item.sortData.label}: {item.sortValues.label}
                                                        </div>
                                                    </div>
                                                    </>
                                                }
                                                {item.filterDetails &&
                                                    <>
                                                    <div className="grid lg:grid-cols-4 gap-4 justify-between ml-5 mr-5 mb-5">
                                                        <div>
                                                            <label
                                                                className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize font-bold"
                                                            >
                                                                Filter Options: 
                                                            </label>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="grid lg:grid-cols-4 gap-4 justify-between ml-5 mr-5 mb-5">
                                                    {item.filterDetails.map((filterData:any)=>
                                                        <div>
                                                            {getFilterUIData(filterData)}
                                                        </div>
                                                    )}
                                                    </div>
                                                    </>
                                                }
                                                {item.placeholderData &&
                                                    <>
                                                    <div className="grid lg:grid-cols-4 gap-4 justify-between ml-5 mr-5 mb-5">
                                                        <div>
                                                            <label
                                                                className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize font-bold"
                                                            >
                                                                Placeholders: 
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="flex gap-4 ml-5 mr-5 mb-5">
                                                        {item.placeholderData.map((placeholder:any)=>
                                                            <button className="text-white btn bg-orange-500 border-orange-500 hover:text-white hover:bg-orange-600 hover:border-orange-600 focus:text-white focus:bg-orange-600 focus:border-orange-600 focus:ring focus:ring-custom-100 active:text-white active:bg-orange-600 active:border-orange-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                                                {placeholder.title}
                                                            </button>
                                                        )}
                                                    </div>
                                                  </>
                                                }
                                            </div>
                                        </Collapse.Content>
                                    </Collapse>
                                </div> 
                            )
                        })}
                            
                        </div>
                    </div>
                </div>
            </div>


            {/* select rail Modal */}
            <Modal show={show} onHide={()=>setShow(false)} id="defaultModal" modal-center="true"
                className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
                dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600">
                <Modal.Header className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
                    closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500">
                    <Modal.Title className="text-16">Select Rail</Modal.Title>
                </Modal.Header>
                <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto mb-5">
                    <div className="flex flex-col rounded-md border border-white-light dark:border-[#1b2e4b] h-[70vh] ">
                        {allRailsData && allRailsData.map((item:any, idx:any) => (
                            <div className='grid' onClick={() => handleAdd(item.id)}>
                                <div key={item.idx} className="border-b flex justify-between border-white-light dark:border-[#1b2e4b] px-4 py-2.5 bg-white text-black hover:bg-primary hover:text-mb-blue shadow-[0_1px_15px_1px_rgba(67,97,238,0.15)]">
                                    <div>
                                        <p>{item.name} </p>
                                        <p className='text-xs'>{item.description}</p>
                                    </div>
                                    <Plus/>
                                </div>
                            </div>
                        ))}
                        <div className="flex justify-end gap-8 mt-4">
                            <button onClick={()=>setShow(false)} className="text-white bg-red-500 border-red-500 btn hover:text-white hover:bg-red-600 hover:border-red-600 focus:text-white focus:bg-red-600 focus:border-red-600 focus:ring focus:ring-red-100 active:text-white active:bg-red-600 active:border-red-600 active:ring active:ring-red-100 dark:ring-custom-400/20">
                                Cancel
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};
export default RailsAddition;