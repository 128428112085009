import React from "react";
import Collapse from "Common/Components/Collapse/Collapse";
import BasicComponent from "./BasicComponent";
import BasicPlaceholderComponent from "./BasicPlaceholderComponent";
import SortComponent from "./SortComponent";
import FilterComponent from "./FilterComponent";
import {
  updateDraftView,
  createRailInstance,
  updateRailInstance,
} from "helpers/mb-api_helper";
import { useParams } from "react-router-dom";

// Icons
import { ChevronDown, ChevronUp } from "lucide-react";

interface Component {
  id: number;
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  setUndo: React.Dispatch<React.SetStateAction<boolean>>;
  setApiSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  active: any;
  setActive: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
}

const PlaceholderComponent: React.FC<Component> = ({
  id,
  formData,
  setFormData,
  setUndo,
  setApiSuccess,
  active,
  setActive,
}) => {
  let { viewId } = useParams();
  //save draft copy
  let handleSaveRails = async () => {
    let finalformData = [...formData];
    if (formData) {
      if (formData[id].placeholderData.length > 0) {
        let railBody = {
          assets: formData[id].placeholderData,
        };
        let railResponse: any;
        if (formData[id].railInstanceId) {
          railResponse = await updateRailInstance(
            formData[id].railInstanceId,
            railBody
          );
        } else {
          railResponse = await createRailInstance(railBody);
        }
        let railInstanceId = railResponse.id;
        finalformData[id] = {
          ...formData[id],
          railInstanceId: railInstanceId,
        };
        setFormData(finalformData);
      }

      try {
        let newFinalformData = finalformData.map((rail: any) => {
          let { placeholderData, uiElement, ...rest } = rail;
          return rest;
        });
        let body = {
          rails: {
            count: newFinalformData.length,
            list: newFinalformData,
          },
        };
        const draftResponse: any = await updateDraftView(viewId, body);
        if (draftResponse) {
          setApiSuccess(true);
          setUndo(true);
        }
      } catch (error) {
        setApiSuccess(false);
        setUndo(false);
      }
    }
  };
  let handleRemove = () => {
    setFormData(() =>
      formData.filter((item: any, formId: number) => id !== formId)
    );
    setUndo(false);
    setApiSuccess(false);
  };
  return (
    <div className="mb-2.5 cursor-grab" key={id}>
      <Collapse isOpen={`${active === id ? "true" : ""}`}>
        <Collapse.Trigger className="flex p-3 mb-2 w-full text-white collapsible-header group/item btn bg-mb-blue border-mb-blue hover:text-white hover:bg-mb-blue hover:border-mb-blue focus:text-white focus:bg-mb-blue focus:border-mb-blue focus:ring focus:ring-custom-100 active:text-white active:bg-mb-blue active:border-mb-blue active:ring active:ring-custom-100 dark:ring-custom-400/20">
          <div className="rtl:mr-2 gap-5 shrink-0">
            <ChevronDown className="hidden size-4 group-[.show]/item:inline-block"></ChevronDown>
            <ChevronUp className="inline-block size-4 group-[.show]/item:hidden"></ChevronUp>
          </div>
          {formData[id].name}
        </Collapse.Trigger>

        <Collapse.Content className="mt-2 mb-0 collapsible-content card">
          <div className="card-body">
            <div className="flex justify-end col-span-2 gap-5">
              <button
                onClick={() => handleSaveRails()}
                className="text-white btn bg-mb-green border-mb-green hover:text-white hover:bg-mb-green hover:border-mb-green focus:text-white focus:bg-mb-green focus:border-mb-green focus:ring focus:ring-custom-100 active:text-white active:bg-mb-green active:border-mb-green active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                Save
              </button>
              <button
                onClick={() => handleRemove()}
                className="text-white bg-mb-red border-mb-red btn hover:text-white hover:bg-mb-red/20 hover:border-mb-red/20 focus:text-white focus:bg-mb-red/20 focus:border-mb-red/20 focus:ring focus:ring-red-100 active:text-white active:bg-mb-red/20 active:border-mb-red/20 active:ring active:ring-red-100 dark:ring-custom-400/20"
              >
                Remove
              </button>
            </div>
          </div>
          <BasicComponent
            id={id}
            formData={formData}
            setFormData={setFormData}
          />
          <BasicPlaceholderComponent
            id={id}
            formData={formData}
            setFormData={setFormData}
          />
          {/* <SortComponent
                        id={id}
                        formData={formData}
                        setFormData={setFormData}
                    />
                    <FilterComponent
                        id={id}
                        formData={formData}
                        setFormData={setFormData}
                    /> */}
        </Collapse.Content>
      </Collapse>
    </div>
  );
};

export default PlaceholderComponent;
