import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// login
import LoginReducer from "./auth/login/reducer";
import UserReducer from "./auth/user/reducer";

// register
//import RegisterReducer from "./auth/register/reducer";

// userProfile
//import ProfileReducer from "./auth/profile/reducer";

const rootReducer = combineReducers({
    Layout: LayoutReducer,
    Login: LoginReducer,
    User:UserReducer,
    
    
});


export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;