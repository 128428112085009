import React from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css"; // Import a Flatpickr theme
import RightDrawer from "Layout/RightDrawer";

interface Component {
  field: string;
  formValues: { [key: string]: any };
  setFormValues: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  element: {
    shortDesc?: string;
    longDesc?: {
      title: string;
      body: string;
    };
    name: string;
    cols: number;
  };
}

const DatePicker: React.FC<Component> = ({
  element,
  field,
  formValues,
  setFormValues,
}) => {
  const handleChange = (selectedDates: Date[]) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: selectedDates[0],
    }));
  };

  return (
    <div className={`lg:col-span-${element.cols || 6}`}>
      <div className="flex flex-col gap-1 mb-2">
        <div className="flex items-center gap-2">
          <label className="inline-block text-base font-medium">
            {element.name}
          </label>
          {element.longDesc && (
            <RightDrawer
              title={element.longDesc.title}
              body={element.longDesc.body}
            />
          )}
        </div>
        {element.shortDesc && element.shortDesc !== "" && (
          <span className="text-sm">{element.shortDesc}</span>
        )}
      </div>
      <Flatpickr
        value={formValues[field] || ""}
        onChange={handleChange}
        options={{
          dateFormat: "d M, Y",
        }}
        placeholder="Select Date"
        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
      />
    </div>
  );
};

export default DatePicker;
