import React from 'react';
import BreadCrumb from 'Common/BreadCrumb';
import { useParams } from 'react-router-dom';

const Analytics = () => {
  const { type } = useParams<{ type: string }>(); // Destructure `type` from useParams
  return (
    <React.Fragment>
      <BreadCrumb title={`${type?type.toUpperCase():''} DASHBOARD`} pageTitle='Dashboards' />
     
    </React.Fragment>
  );
};

export default Analytics;
