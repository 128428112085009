import { setUser,setMeta, setMetaError } from "./reducer";
import { ThunkAction } from "redux-thunk";
import { Action, Dispatch } from "redux";
import { RootState } from "slices";
import { getMetaConfig, getUser } from "helpers/mb-api_helper";

export const setUserData = () :ThunkAction<void, RootState,unknown,Action<string>> => async(dispatch:Dispatch) => {
    
    try{
        let response:any;
        let metaResponse:any;
        response = await getUser()
        metaResponse = await getMetaConfig()
        localStorage.setItem("authUser",JSON.stringify({...response.details,meta:metaResponse}))
        dispatch(setUser({...response.details}))
        dispatch(setMeta(metaResponse))
    }catch(e){
        dispatch(setMetaError({}))
        localStorage.removeItem("authUser")
        localStorage.removeItem("authToken")

    }
}
