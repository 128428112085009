import { AxiosResponse } from "axios";
import { capitalizeFirstLetter } from "coustumFuntions/capitalFirstLetter";
import {
  getAssetInfo,
  getContent,
  searchAssetByType,
} from "helpers/mb-api_helper";
import RightDrawer from "Layout/RightDrawer";
import FormComponent from "pages/ContentManagement/FormComponent";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";

interface Contributor {
  [key: string]: string | number | boolean; // Allows for dynamic property names
}
interface RecordsData {
  [key: string]: any; // Allows any key-value pairs in the user object
}
interface Option {
  readonly label: string;
  readonly value: string;
  readonly isDisabled?: boolean;
}
interface Component {
  field: string;
  formValues: { [key: string]: any };
  setFormValues: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  element: {
    shortDesc?: string;
    longDesc?: {
      title: string;
      body: string;
    };
    name: string;
    cols: number;
    items: {
      properties: {
        [key: string]: any;
      };
    };
  };
}

const MediaSelectBox: React.FC<Component> = ({
  field,
  formValues,
  setFormValues,
  element,
}) => {
  const selectUserMeta = (state: any) => state.User.meta;
  const meta = useSelector(selectUserMeta);

  const newContributor = {};
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [contributorsData, setContributorsData] = useState<Contributor[]>(
    formValues[field] || [newContributor]
  );
  const [showMore, setShowMore] = useState(
    formValues[field]?.length > 0
      ? formValues[field].map((_: string) => false)
      : [true]
  );
  const [assetType, setAssetType] = useState<string>("");
  const [assetsOptions, setAssetsOption] = useState<any>([]); // State to store assets options
  const [asset, setAsset] = useState<any>({ value: "", label: "" }); // State to store assets data
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [properties, setProperties] = useState<string[]>([]);

  const assetTypeOptions = meta.assetTypes.map((data: string) => ({
    value: data,
    label: capitalizeFirstLetter(data),
  }));

  const handleAssetTypeChange = (selectedOption: Option | null) => {
    setAssetType(selectedOption ? selectedOption.value : "");
  };

  const handleAssetChange = (selectedOption: any | null) => {
    setAsset(selectedOption);
    const getContentInfo = async () => {
      try {
        const contentRes: any = await getContent(
          selectedOption.value,
          assetType
        );
        const assetArray = contentRes[field] || [];
        setContributorsData((preVal) => [...preVal, ...assetArray]);
        setShowMore((preVal: boolean[]) => [
          ...preVal,
          ...assetArray.map((data: string) => false),
        ]);
        setFormValues((prevValues) => ({
          ...prevValues,
          [field]: [...contributorsData, ...assetArray],
        }));
        setIsDropdownOpen(false);
        // Extract the data from the response and set it in the state
      } catch (error) {
        console.error("Error fetching assets:", error);
      }
    };
    if (selectedOption && assetType !== "") {
      getContentInfo(); // Call the async function to fetch assets
    }
  };

  const handleSearchAsset = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setAsset({ value: e.target.value, label: e.target.value });
    try {
      const response: AxiosResponse<RecordsData> = await searchAssetByType(
        assetType,
        e.target.value
      );
      const responseData: any = response;
      setAssetsOption(
        responseData.list?.map((data: any) => ({
          label: data.title,
          value: data.id,
        }))
      );
      // Extract the data from the response and set it in the state
    } catch (error) {
      console.error("Error fetching assets:", error);
    }
  };

  const removeContributor = (index: number) => {
    const updatedContributors = contributorsData.filter((_, i) => i !== index);
    setContributorsData(updatedContributors);
    setShowMore((preVal: boolean[]) =>
      preVal.filter((data: boolean, i: number) => i !== index)
    );
    // setFormValues((prevValues) => ({
    //   ...prevValues,
    //   [field]: updatedContributors,
    // }));
  };

  const addContributor = () => {
    setContributorsData([...contributorsData, newContributor]);
    setShowMore([...showMore, false]);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  };

  const handleFieldChange = (index: number, updateFunc: any) => {
    // console.log("handleField === ",updateFunc(obj) );
    setContributorsData((prevData: any) =>
      prevData.map((obj: any, i: number) =>
        i === index ? updateFunc(obj) : obj
      )
    );
  };

  useEffect(() => {
    setAsset({ value: "", label: "" });
    const fetchAssets = async () => {
      try {
        const response: AxiosResponse<RecordsData> = await getAssetInfo(
          assetType
        ); // Await the API call to fetch asset data
        const responseData: any = response;
        setAssetsOption(
          responseData.list?.map((data: any) => ({
            label: data.title,
            value: data.id,
          }))
        );
        // Extract the data from the response and set it in the state
      } catch (error) {
        console.error("Error fetching assets:", error);
      }
    };

    if (assetType !== "") {
      fetchAssets(); // Call the async function to fetch assets
    }
  }, [assetType]);

  useEffect(() => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: contributorsData,
    }));
  }, [contributorsData, field, setFormValues]);

  useEffect(() => {
    const objectsArray = Object.keys(element.items.properties);
    setProperties(objectsArray);
  }, [element]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className={`lg:col-span-${element.cols || 12}`}>
      <div className="">
        <div className="flex flex-col gap-1 mb-2">
          <div className="flex items-center gap-2">
            <h5>{element.name}</h5>
            {element.longDesc && (
              <RightDrawer
                title={element.longDesc.title}
                body={element.longDesc.body}
              />
            )}
          </div>
          {element.shortDesc && element.shortDesc !== "" && (
            <span className="text-sm">{element.shortDesc}</span>
          )}
        </div>
        <div className="flex flex-col gap-4">
          {contributorsData.map((contributor, index) => (
            <div
              key={index}
              className="relative grid grid-cols-1 gap-5  lg:grid-cols-12  mt-3 border rounded-lg shadow-lg p-4"
            >
              {properties?.slice(0, 2)?.map((f, i) => {
                if (element.items.properties[f].showInUi)
                  return (
                    <FormComponent
                      key={i}
                      field={f}
                      formValues={contributor}
                      setFormValues={(updatedField) =>
                        handleFieldChange(index, updatedField)
                      }
                      element={element.items.properties[f]}
                    />
                  );
                return null;
              })}
              <div className="absolute top-2 right-2">
                {contributorsData?.length > 1 && (
                  <button
                    className="text-mb-red/90 hover:text-mb-red"
                    onClick={() => removeContributor(index)}
                  >
                    {" "}
                    <svg
                      className="w-6 h-6 "
                      fill="currentColor"
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="CancelIcon"
                    >
                      <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2m5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12z"></path>
                    </svg>
                  </button>
                )}
              </div>
              <div
                className="lg:col-span-12 flex gap-0.5 cursor-pointer text-gray-800 text-xs items-center"
                onClick={() =>
                  setShowMore((preVal: boolean[]) =>
                    preVal.map((data: boolean, i: number) =>
                      i === index ? !data : data
                    )
                  )
                }
              >
                  <svg
                    className={`w-5 h-5 ${showMore[index]?'rotate-180':'rotate-90'} transition-all duration-500`}
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24" 
                  >
                    <path d="m7 14 5-5 5 5z"></path>
                  </svg>
                
                <p>Advance Options</p>
              </div>
              {showMore[index] &&
                properties?.slice(2)?.map((f, i) => {
                  if (element.items.properties[f].showInUi)
                    return (
                      <FormComponent
                        key={i}
                        field={f}
                        formValues={contributor}
                        setFormValues={(updatedField) =>
                          handleFieldChange(index, updatedField)
                        }
                        element={element.items.properties[f]}
                      />
                    );
                  return null;
                })}
            </div>
          ))}
          <div className="flex gap-4 items-center">
            <button
              type="button"
              className="btn bg-mb-blue text-white"
              onClick={addContributor}
            >
              Add New
            </button>
            <p className="text-xs">Or Copy From</p>
            <Select
              className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              data-choices
              name="choices-single-default"
              options={assetTypeOptions}
              onChange={handleAssetTypeChange}
              value={assetTypeOptions.find(
                (option: Option) => option.value === assetType
              )}
            />{" "}
            {assetType && (
              <div className="relative" ref={dropdownRef}>
                <input
                  type="text"
                  placeholder={`Search ${assetType}...`}
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  onChange={handleSearchAsset}
                  onFocus={() => setIsDropdownOpen(true)}
                  value={asset.label}
                />
                {isDropdownOpen && (
                  <div className="absolute top-full left-0 w-full flex flex-col gap-2 p-1 bg-white border border-slate-200 dark:bg-zink-700 dark:border-zink-500 z-10">
                    {assetsOptions.length === 0 && (
                      <p className="p-1">No result found</p>
                    )}
                    {assetsOptions?.map((assetOption: Option) => (
                      <p
                        key={assetOption.value}
                        className="p-1 truncate hover:bg-slate-200 dark:hover:bg-zink-600 cursor-pointer"
                        onClick={() => {
                          handleAssetChange(assetOption);
                        }}
                      >
                        {assetOption.label}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaSelectBox;
