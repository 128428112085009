import React, { useCallback, useEffect } from "react";
import { Check, Cross, UploadCloud, X } from "lucide-react";
import { useDropzone } from "react-dropzone";
import languages from "Common/languages";
import isoLangs from "lib/languages";
import CreatableSelect from "react-select/creatable";
import { Promise } from "bluebird";
import { randomUUID } from "crypto";
import { v4 as uuidv4 } from "uuid";

import { getPreSignedUrl, uploadFile } from "helpers/mb-api_helper";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import RightDrawer from "Layout/RightDrawer";


function ratio (wi:number, he:number){
  var w = wi;
  var h = he;
  var d:number=1; //gcd
  if (w < 0)w = -w;
    if (h < 0) h = -h;
    if (h > w) {var temp = w; w = h; h = temp;}
    var t = true;
    while (t) {
        w %= h;
        if (w == 0){
         d = h;
         t = false;
        }
        h %= w;
        if (h == 0){
         d = w;
         t = false;
        } 
    }
  return wi/d + ':' + he/d;
}
interface Component {
  field: string;
  formValues: { [key: string]: any };
  setFormValues: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  element: {
    shortDesc?: string;
    longDesc?: {
      title: string;
      body: string;
    };
    name: string;
    accept: string;
    cols: number;
  };
  setChangeTheFormData?: React.Dispatch<React.SetStateAction<boolean>>;
}
interface Option {
  readonly label: string;
  readonly value: string;
  readonly isDisabled?: boolean;
}

const ImageBoxNoRatio: React.FC<Component> = ({
  field,
  formValues,
  element,
  setFormValues,
  setChangeTheFormData = () => {},
}) => {
  let selectProperties = createSelector(
    (state: any) => state.User,
    (user) => ({
      meta: user.meta,
    })
  );
  let { meta } = useSelector(selectProperties);

  const formatBytes = (bytes: any, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  const getBaseName = (path: string) => {
    let path_array = path.split("/");
    return path_array[path_array.length - 1];
  };
  const [selectedFiles, setSelectedFiles] = React.useState<any>([]);
  const [progress, setProgress] = React.useState<number>(0);
  const [currentlyUploading, setCurrentlyUploading] =
    React.useState<number>(-1);
  const [isUploading, setIsUploading] = React.useState<Boolean>(false);
  const [isReadyToUpload, setIsReadyToUpload] = React.useState<Boolean>(false);
  const [options, setOptions] = React.useState<Option[]>([]);
  const [error, setError] = React.useState<string>("");

  const onDrop = (files: any) => {
    
    files.map((file: any) =>{
      let img = new Image();
      var objectUrl = URL.createObjectURL(file);
      img.src = objectUrl;
      img.onload = function (this:any) {
        Object.assign(file, {
          preview: objectUrl,
          formattedSize: formatBytes(file.size),
          language: meta.lang,
          uploaded: false,
          width:this.width,
          height:this.height,
          ratio:ratio(this.width,this.height)
        })
        setSelectedFiles(files);
      };
      
      
    }
    );
    
  };
  useEffect(() => {
    setOptions(
      meta.languages.map((key: string) => ({
        value: key,
        label: isoLangs[key].name,
      }))
    );
  }, [meta]);

  const handleChange = useCallback(
    (file: any, selectedOption: Option | null) => {
      if (file.name) {
        let newSelectedFiles = selectedFiles.map((f: any, i: number) => {
          if (f.name === file.name) {
            f.language = selectedOption?.value;
          }
          return f;
        });
        setSelectedFiles([...newSelectedFiles]);
      } else {
        let newSelectedFiles = formValues[field].map((f: any, i: number) => {
          if (f.path === file.path) {
            f.language = selectedOption?.value;
          }
          return f;
        });
        let newFormValues = { ...formValues };
        newFormValues[field] = [...newSelectedFiles];
        setChangeTheFormData(true);
        setFormValues({ ...newFormValues });
      }
    },
    [selectedFiles]
  );
  const startUpload = useCallback(async () => {
    let fileResults = await Promise.mapSeries(
      selectedFiles,
      async (file: any, index: number) => {
        console.log("file",file)
        setCurrentlyUploading(index);
        let contentId = formValues.id;
        let name = uuidv4();
        let signedUrl: any;
        signedUrl = await getPreSignedUrl(
          `${contentId}/posters/${name}.${file.type.replace("image/", "")}`
        );
        let uploadFileRes = await uploadFile(signedUrl.url, file);

        if (uploadFileRes.status === 200) {
          file.uploaded = true;
          file.uploadPath = signedUrl.file;
        } else {
          file.uploaded = false;
        }
        setProgress(Math.round(((index + 1) * 100) / selectedFiles.length));
        setCurrentlyUploading(-1);
        console.log(
          "Final Res",
          "Index:" + index,
          "Progress:" + progress,
          "Path:" + file.uploadPath
        );
        return file;
      }
    );
    console.log("formValues", fileResults);
    let newUploadedFiles = fileResults.map((file: any) => ({
      path: file.uploadPath,
      language: file.language,
      width:file.width,
      height:file.height,
      ratio:file.ratio
    }));
    let newFormValues = { ...formValues };
    newFormValues[field] = [
      ...(newFormValues[field] ? newFormValues[field] : []),
      ...newUploadedFiles,
    ];
    setFormValues(newFormValues);
    setSelectedFiles([]);
    setIsReadyToUpload(false);
  }, [selectedFiles]);
  useEffect(() => {
    if (selectedFiles.length > 0) setIsReadyToUpload(true);
  }, [selectedFiles]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg", ".webp", ".JPG", ".PNG", ".JPEG"],
    },
  });

  return (
    <div className={`lg:col-span-${element.cols || 12}`}>
      <div className="">
        <div className="flex flex-col gap-1 mb-2">
          <div className="flex items-center gap-2">
            <h6>{element.name}</h6>
            {element.longDesc && (
              <RightDrawer
                title={element.longDesc.title}
                body={element.longDesc.body}
              />
            )}
          </div>
          {element.shortDesc && element.shortDesc !== "" && (
            <span className="text-sm">{element.shortDesc}</span>
          )}
        </div>
        <div className="flex items-center justify-center border rounded-md cursor-pointer bg-slate-100 dropzone border-slate-200 dark:bg-zink-600 dark:border-zink-500 dz-clickable">
          <div
            className="w-full py-5 text-lg text-center dz-message needsclick"
            {...getRootProps()}
          >
            <input {...getInputProps()} accept={element.accept} />
            <div className="mb-3">
              <UploadCloud className="block size-12 mx-auto text-slate-500 fill-slate-200 dark:text-zink-200 dark:fill-zink-500"></UploadCloud>
            </div>

            <h5 className="mb-0 font-normal text-slate-500 text-15">
              Drag and drop your files or <a href="#!">browse</a> your files
            </h5>
          </div>
        </div>
        {isReadyToUpload && (
          <button
            onClick={startUpload}
            className="bg-mb-blue text-white px-4 py-2 mt-1 mx-auto rounded-lg"
          >
            Start Upload
          </button>
        )}

        {progress > 0 && (
          <div className="w-full bg-slate-200 rounded-full mt-1 h-2.5 dark:bg-zink-600">
            <div
              className="bg-mb-blue h-2.5 rounded-full animate-progress relative"
              style={{ width: `${progress}%` }}
            >
              <div className="absolute ltr:left-full ltr:-translate-x-1/2 rtl:left-0 inline-block px-2 py-0.5 text-[10px] text-white bg-mb-blue rounded -top-6 after:absolute after:border-4 ltr:after:right-1/2 rtl:after:left-1/2 after:-bottom-2 after:border-transparent after:border-t-mb-blue">
                {progress}%
              </div>
            </div>
          </div>
        )}
        <ul className="mb-0" id="dropzone-preview">
          {(selectedFiles || [])?.map((f: any, i: any) => {
            return (
              <li className="mt-1" id="dropzone-preview-list" key={i + "-file"}>
                <div className="border rounded border-slate-200 dark:border-zink-500">
                  <div className="flex flex-col lg:flex-row p-2 gap-4 items-center justify-start">
                    <div className="shrink-0 me-3">
                      <div className={`p-2 rounded-md size-14 ${meta.imageSizes.indexOf(f.ratio)>-1?'bg-mb-green':'bg-mb-red'} relative`}>
                        <img
                          data-dz-thumbnail
                          className="block w-full h-full rounded-md"
                          src={f.preview}
                          alt={f.name}
                        />
                        {meta.imageSizes.indexOf(f.ratio)>-1?<div className="bg-gray-200 rounded-full p-0.5 absolute right-0 bottom-0 z-10"><Check size={15} className="text-green-600" /></div>:<div className="bg-gray-200 rounded-full p-0.5 absolute right-0 bottom-0 z-10"><X size={15} className="text-mb-red" /></div>}
                      </div>
                    </div>
                    <div className="grow">
                      <div className="pt-1 flex flex-col items-start">
                        <h5 className="mb-1 text-15" data-dz-name>
                          {f.name}
                        </h5>
                        <div className="flex gap-2 items-center">
                          <p className="p-1 bg-mb-red text-white">New</p>
                          {f.width} x {f.height} - ({f.ratio})
                          {meta.imageSizes.indexOf(f.ratio)>-1?<p className="font-bold text-green-700">Aspect ratio matches at least one rail.</p>:<p className="font-bold text-mb-red">Aspect ratio is not compatible with any rails</p>}
                        </div>
                      </div>
                    </div>
                    <div className="shrink-0 ms-3">
                      <div className="">
                        <h6 className="mb-1 text-15">Select Language</h6>
                        <CreatableSelect
                          className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                          id="choices-single-no-search"
                          name="choices-single-no-search"
                          isClearable
                          isSearchable={true}
                          options={options}
                          onChange={(selectedOption: Option | null) => {
                            handleChange(f, selectedOption);
                          }}
                          value={
                            options.find(
                              (option) => option.value === f.language
                            ) || null
                          }
                        />
                      </div>
                    </div>
                    <div className="shrink-0 ms-3">
                      {currentlyUploading === i ? (
                        <div className="inline-block size-8 border-2 rounded-full animate-spin border-l-transparent border-mb-blue"></div>
                      ) : (
                        <button
                          data-dz-remove
                          className="px-2 py-1.5 text-xs text-white bg-mb-red border-mb-rebg-mb-red btn hover:text-white hover:bg-mb-red/20 hover:border-mb-red/20 focus:text-white focus:bg-mb-red/20 focus:border-mb-red/20 focus:ring focus:ring-red-100 active:text-white active:bg-mb-red/20 active:border-mb-red/20 active:ring active:ring-red-100 dark:ring-custom-400/20"
                          onClick={() => {
                            const newImages = [...selectedFiles];
                            newImages.splice(i, 1);
                            setSelectedFiles(newImages);
                          }}
                        >
                          {currentlyUploading === i ? "Uploading..." : "Remove"}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
          {(formValues[field] || [])?.map((f: any, i: any) => {
            return (
              <li
                className="mt-1 bg-gray-100"
                id="dropzone-preview-list"
                key={i + "-file"}
              >
                <div className="border rounded border-slate-200 dark:border-zink-500">
                  <div className="flex flex-col lg:flex-row p-2 gap-4 items-center justify-start">
                    <div className="shrink-0 me-3">
                      <div className={`p-2 rounded-md size-14 ${meta.imageSizes.indexOf(f.ratio)>-1?'bg-mb-green':'bg-mb-red'} relative`}>
                        <img
                          data-dz-thumbnail
                          className="block w-full h-full rounded-md"
                          src={process.env.REACT_APP_IMAGE_CDN + f.path}
                          alt={"image title"}
                        />
                        {meta.imageSizes.indexOf(f.ratio)>-1?<div className="bg-gray-200 rounded-full p-0.5 absolute right-0 bottom-0 z-10"><Check size={15} className="text-green-600" /></div>:<div className="bg-gray-200 rounded-full p-0.5 absolute right-0 bottom-0 z-10"><X size={15} className="text-mb-red" /></div>}
                      </div>
                    </div>
                    <div className="grow">
                      <div className="pt-1 flex flex-col items-start">
                        <h5 className="mb-1 text-15" data-dz-name>
                          {getBaseName(f.path)}
                        </h5>
                        <div className="flex gap-2 items-center">
                          <p className="p-1 bg-mb-blue text-white">Uploaded</p>
                          {f.width} x {f.height} - ({f.ratio})                          
                        </div>
                      </div>
                    </div>
                    <div className="shrink-0 ms-3">
                      <div className="">
                        <h6 className="mb-1 text-15">Select Language</h6>
                        <CreatableSelect
                          className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                          id="choices-single-no-search"
                          name="choices-single-no-search"
                          isClearable
                          isSearchable={true}
                          options={options}
                          onChange={(selectedOption: Option | null) => {
                            handleChange(f, selectedOption);
                          }}
                          value={
                            options.find(
                              (option) => option.value === f.language
                            ) || null
                          }
                        />
                      </div>
                    </div>
                    <div className="shrink-0 ms-3">
                      <button
                        data-dz-remove
                        className="px-2 py-1.5 text-xs text-white bg-mb-red border-mb-rebg-mb-red btn hover:text-white hover:bg-mb-red/20 hover:border-mb-red/20 focus:text-white focus:bg-mb-red/20 focus:border-mb-red/20 focus:ring focus:ring-red-100 active:text-white active:bg-mb-red/20 active:border-mb-red/20 active:ring active:ring-red-100 dark:ring-custom-400/20"
                        onClick={() => {
                          const newImages = [...formValues[field]];
                          newImages.splice(i, 1);
                          let newFormValues = { ...formValues };
                          newFormValues[field] = newImages;
                          setFormValues({ ...newFormValues });
                        }}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ImageBoxNoRatio;
