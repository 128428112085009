import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import TableContainer from "Common/TableContainer";
import { Dropdown } from "Common/Components/Dropdown";
import BreadCrumb from "Common/BreadCrumb";
import { ToastContainer } from "react-toastify";
import Select from "react-select";
import {
  getViews,
  getPlatformById,
  createView,
  searchAssets,
} from "helpers/mb-api_helper";
import { slugify } from "helpers/utility";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

// Icons
import {
  Search,
  Plus,
  ChevronsDown,
  FileEdit,
  CheckCircle,
  Loader,
} from "lucide-react";
import Modal from "Common/Components/Modal";
import { any } from "bluebird";

const ListView: React.FC = () => {
  const selectProperties = createSelector(
    (state: any) => state.User,
    (user) => ({
      meta: user.meta,
    })
  );
  const { meta } = useSelector(selectProperties);

  const viewTypeOptions = [
    { value: "single", label: "Single" },
    { value: "listing", label: "Listing" },
  ];
  const [viewContentTypeOptions, setViewContentTypeOptions] = useState(
    meta.assetTypes
      ? meta.assetTypes.map((v: any) => {
          return { label: v, value: v };
        })
      : []
  );

  const { id } = useParams();
  const [views, setViews] = useState<any>([]); // State to store views data
  const [show, setShow] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [slug, setSlug] = useState<any>("");
  const [viewId, setViewId] = useState<any>("");
  const [status, setStatus] = useState<string>("draft");
  const [extendFrom, setExtendFrom] = useState<any>(null);
  const [canExtend, setCanExtend] = useState<boolean>(false);
  const [canExtendDisabled, setCanExtendDisabled] = useState<boolean>(false);
  const [canExtendChecked, setCanExtendChecked] = useState<any>("");
  const [extendFromOptions, setExtendFromOptions] = useState<any>([]);
  const [viewType, setViewType] = useState<any>(null);
  const [viewContentType, setViewContentType] = useState<any>(null);
  const [viewContentId, setViewContentId] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [viewContentIdOptions, setViewContentIdOptions] = useState<any>([]);

  const [pageSize, setPageSize] = useState<any>(20);

  const columns = useMemo(
    () => [
      {
        header: "ID",
        accessorKey: "id",
        enableColumnFilter: false,
      },
      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "v_status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => <Status item={cell.getValue()} />,
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <>
            <div className="flex gap-4">
              <Link
                className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                to={`/platforms/views/${id}/${cell.row.original.id}`}
              >
                <FileEdit className="inline-block size-3 ltr:mr-1 rtl:ml-1" />
                <span className="align-middle">Edit</span>
              </Link>
              
              {cell.row.original.v_platform !== id ? (
                <div
                  className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                  onClick={()=>handleExtendView(cell.row.original)}
                >
                  <ChevronsDown className="inline-block size-3 ltr:mr-1 rtl:ml-1" />
                  <span className="align-middle">Extend</span>
                </div>
              ) : (
                <div className="relative inline-block w-10 align-middle transition duration-200 ease-in ltr:mr-2 rtl:ml-2"></div>
              )}
            </div>
          </>
        ),
      },
    ],
    []
  );

  // columns
  const Status = ({ item }: any) => {
    switch (item) {
      case "live":
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-mb-green dark:bg-mb-green/20 dark:border-transparent inline-flex items-center status">
            <CheckCircle className="size-3 mr-1.5" />
            {item}
          </span>
        );
      case "draft":
        return (
          <span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-slate-100 border-transparent text-slate-500 dark:bg-slate-500/20 dark:text-zink-200 dark:border-transparent status">
            <Loader className="size-3 mr-1.5" />
            {item}
          </span>
        );
      default:
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-mb-green dark:bg-mb-green/20 dark:border-transparent inline-flex items-center status">
            <CheckCircle className="size-3 mr-1.5" />
            {item}
          </span>
        );
    }
  };
  // const getDataNew = useCallback(async () => {
  //   try {
  //     const platRes: any = await getPlatformById(id); // Await the API call to fetch platform by id
  //     // console.log('platFormData',platRes.v_parent)
  //     if (platRes.v_parent) {
  //       try {
  //         // parent platform views
  //         let parentPlatFilters: any = {
  //           v_platform: platRes.v_parent,
  //         };
  //         const parentViewRes: any = await getViews(parentPlatFilters); // Await the API call to fetch view by platform
  //         let parentViewRecords = parentViewRes.list;
  //         // console.log('parentViewRes',parentViewRes)
  //         try {
  //           // current platform views
  //           let currPlatFilters: any = {
  //             v_platform: id,
  //           };
  //           const currViewRes: any = await getViews(currPlatFilters); // Await the API call to fetch view by platform
  //           let currViewRecords = currViewRes.list;

  //           const mergedRecords: any = currViewRecords.concat(
  //             parentViewRecords.filter(
  //               (extView: any) =>
  //                 !currViewRecords.some(
  //                   (curView: any) => extView.id === curView.v_parent
  //                 )
  //             )
  //           );

  //           // setViews([...mergedRecords]);
  //           setViews((prevState:any) => ([
  //             ...prevState, ...mergedRecords
  //           ]));
            
  //           console.log("set1", mergedRecords);
  //           setExtendFromOptions(
  //             Object.keys(currViewRecords).map((v: any) => {
  //               return {
  //                 value: currViewRecords[v].id,
  //                 label: currViewRecords[v].id,
  //               };
  //             })
  //           );
  //         } catch (e) {
  //           console.log(e);
  //         }
  //       } catch (e) {
  //         console.log(e);
  //       }
  //     } else {
  //       try {
  //         // current platform views
  //         let currPlatFilters: any = {
  //           v_platform: id,
  //         };
  //         const currViewRes: any = await getViews(currPlatFilters); // Await the API call to fetch view by platform
  //         let currViewRecords = currViewRes.list;

  //         setViews([...currViewRecords]);
  //         console.log("set2", currViewRecords);
  //         setExtendFromOptions(
  //           Object.keys(currViewRecords).map((v: any) => {
  //             return {
  //               value: currViewRecords[v].id,
  //               label: currViewRecords[v].id,
  //             };
  //           })
  //         );
  //       } catch (e) {
  //         console.log(e);
  //       }
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }, []);

  useEffect(() => {
    let getData = async () => {
      try {
        const platRes: any = await getPlatformById(id); // Await the API call to fetch platform by id
        // console.log('platFormData',platRes.v_parent)
        if (platRes.v_parent) {
          try {
            // parent platform views
            let parentPlatFilters: any = {
              v_platform: platRes.v_parent,
            };
            const parentViewRes: any = await getViews(parentPlatFilters); // Await the API call to fetch view by platform
            let parentViewRecords = parentViewRes.list;
            // console.log('parentViewRes',parentViewRes)
            try {
              // current platform views
              let currPlatFilters: any = {
                v_platform: id,
              };
              const currViewRes: any = await getViews(currPlatFilters); // Await the API call to fetch view by platform
              let currViewRecords = currViewRes.list;

              const mergedRecords: any = currViewRecords.concat(
                parentViewRecords.filter(
                  (extView: any) =>
                    !currViewRecords.some(
                      (curView: any) => extView.id === curView.v_parent
                    )
                )
              );

              setViews([...mergedRecords]);
              console.log("set1", mergedRecords);
              setExtendFromOptions(
                Object.keys(currViewRecords).map((v: any) => {
                  return {
                    value: currViewRecords[v].id,
                    label: currViewRecords[v].id,
                  };
                })
              );
            } catch (e) {
              console.log(e);
            }
          } catch (e) {
            console.log(e);
          }
        } else {
          try {
            // current platform views
            let currPlatFilters: any = {
              v_platform: id,
            };
            const currViewRes: any = await getViews(currPlatFilters); // Await the API call to fetch view by platform
            let currViewRecords = currViewRes.list;

            setViews([...currViewRecords]);
            console.log("set2", currViewRecords);
            setExtendFromOptions(
              Object.keys(currViewRecords).map((v: any) => {
                return {
                  value: currViewRecords[v].id,
                  label: currViewRecords[v].id,
                };
              })
            );
          } catch (e) {
            console.log(e);
          }
        }
      } catch (e) {
        console.log(e);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    let getData = async () => {
      if (viewContentType) {
        try {
          // Await the API call to fetch assets of type
          let currTypeFilters: any = {
            v_type: viewContentType,
          };
          let assetsRes: any;
          if (searchQuery) {
            assetsRes = await searchAssets(currTypeFilters, searchQuery);
          } else {
            assetsRes = await searchAssets(currTypeFilters);
          }
          let assets = assetsRes.list;

          const fetchedOptions = assets.map((item: any) => ({
            value: item.id,
            label: item.title,
          }));
          // console.log(fetchedOptions)
          setViewContentIdOptions(fetchedOptions);
        } catch (e) {
          console.log(e);
        }
      }
    };
    getData();
  }, [searchQuery, viewContentType]);

  useEffect(() => {
    console.log("views", views);
    console.log("extendFromOptions", extendFromOptions);
  }, [views, extendFromOptions]);

  let handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      let postData = {
        name: name,
        slug: slug,
        id: viewId,
        v_can_extend: canExtend,
        v_status: status,
        v_parent: extendFrom,
        v_platform: id,
        view_type: viewType,
        view_content_type: viewContentType,
        view_content_id: viewContentId,
      };
      const response: any = await createView(JSON.stringify(postData)); // Await the API call to create platforms
      setViews((prev: any) => [response, ...prev]);

      setName("");
      setSlug("");
      setViewId("");
      setExtendFrom(null);
      setCanExtend(false);
      setCanExtendChecked(false);
      setCanExtendDisabled(false);
      setViewType(null);
      setViewContentType(null);
      setViewContentId(null);
      setShow(false);
    } catch (error) {
      console.log(error);
    }
  };

  let handleExtendView = async (data: any) => {
    console.log("extended view", data.name);
    console.log("status", status);
    console.log("views11--", views);
    try {
      let postData = {
        name: data.name,
        slug: slugify(data.name),
        id: slugify(data.name),
        v_can_extend: false,
        v_status: "draft",
        v_parent: data.id,
        v_platform: id,
        view_type: data.view_type,
        view_content_type: data.view_content_type,
        view_content_id: data.view_content_id,
      };
      const response: any = await createView(JSON.stringify(postData)); // Await the API call to create platforms
      let newViewData = response;
      console.log("views--a--", views);
      console.log("response new", response);
      let filteredRecords = views.filter(
        (view: any) => view.id !== newViewData.v_parent
      );
      console.log("filteredRecords", filteredRecords);
      filteredRecords.push(newViewData);
      console.log("filteredRecords new", filteredRecords);

      setViews(filteredRecords);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    // console.log(canExtend, canExtendDisabled, canExtendChecked)
  }, [canExtend, canExtendDisabled, canExtendChecked]);

  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
      setName("");
      setSlug("");
      setViewId("");
      setExtendFrom(null);
      setCanExtend(false);
      setCanExtendChecked(false);
      setCanExtendDisabled(false);
    } else {
      setShow(true);
    }
  }, [show]);

  const handleChangeName = (event: any) => {
    setName(event.target.value);
    setSlug(slugify(event.target.value));
    setViewId(slugify(event.target.value));
  };
  let handleChangeCanExtend = (event: any) => {
    console.log(event.target.checked);
    setCanExtend(event.target.checked);
    setCanExtendChecked(event.target.checked);
  };
  let handleChangeExtendFrom = (selected: any) => {
    setExtendFrom(selected.value);
    setCanExtend(false);
    setCanExtendChecked(false);
    setCanExtendDisabled(true);
  };
  let handleChangeViewType = (selected: any) => {
    setViewType(selected.value);
  };
  let handleChangeViewContentType = (selected: any) => {
    setViewContentType(selected.value);
  };
  let handleChangeViewContentId = (selected: any) => {
    setViewContentId(selected.value);
  };
  let handleChangeSearch = (newValue: any) => {
    setSearchQuery(newValue);
  };

  return (
    <React.Fragment>
      <BreadCrumb title={`Manage Views - ${id}`} pageTitle="" />
      {/* <ToastContainer closeButton={false} limit={1} /> */}
      <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
        <div className="xl:col-span-12">
          <div className="card" id="usersTable">
            <div className="card-body">
              <div className="flex items-center">
                <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                  <div className="relative xl:col-span-2">
                    <input
                      type="text"
                      className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      placeholder="Search views..."
                      autoComplete="off"
                    />
                    <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                  </div>
                </div>
                <div className="shrink-0">
                  <button
                    type="button"
                    className="text-white btn bg-mb-blue border-mb-blue hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                    onClick={toggle}
                  >
                    <Plus className="inline-block size-4" />{" "}
                    <span className="align-middle">Add View</span>
                  </button>
                </div>
              </div>
            </div>

            <div className="card-body">
              {views && views.length > 0 ? (
                <TableContainer
                  isPagination={true}
                  columns={columns || []}
                  data={views || []}
                  customPageSize={
                    views.length > pageSize ? pageSize : views.length
                  }
                  divclassName="-mx-5 -mb-5 overflow-x-auto"
                  tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                  theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-mb-blue [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
                  thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
                  tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5"
                  PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
                />
              ) : (
                <div className="noresult">
                  <div className="py-6 text-center">
                    <Search className="size-6 mx-auto text-mb-blue fill-sky-100 dark:mb-blue/20" />
                    <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* add view Modal */}
      <Modal
        show={show}
        onHide={toggle}
        id="defaultModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-mb-red"
        >
          <Modal.Title className="text-16">Add View</Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <div className="mb-3">
            <label
              htmlFor="name"
              className="inline-block mb-2 text-base font-medium"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              placeholder="Enter name"
              name="name"
              onChange={handleChangeName}
              value={name}
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="type"
              className="inline-block mb-2 text-base font-medium"
            >
              Type
            </label>
            <Select
              value={viewType ? { value: viewType, label: viewType } : null}
              onChange={handleChangeViewType}
              options={viewTypeOptions}
              placeholder="Select options"
              className="selectize capitalize"
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="contentType"
              className="inline-block mb-2 text-base font-medium"
            >
              Content Type
            </label>
            <Select
              value={
                viewContentType
                  ? { value: viewContentType, label: viewContentType }
                  : null
              }
              onChange={handleChangeViewContentType}
              options={viewContentTypeOptions}
              placeholder="Select options"
              className="selectize capitalize"
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="contentId"
              className="inline-block mb-2 text-base font-medium"
            >
              Content
            </label>
            <Select
              value={
                viewContentId
                  ? { value: viewContentId, label: viewContentId }
                  : null
              }
              onChange={handleChangeViewContentId}
              onInputChange={handleChangeSearch}
              options={viewContentIdOptions}
              placeholder="Select options"
              className="selectize capitalize"
            />
          </div>

          <div className="flex gap-10 mb-3">
            <label htmlFor="v_can_extend" className="align-middle">
              Can Extend
            </label>
            <input
              id="v_can_extend"
              onChange={handleChangeCanExtend}
              checked={canExtendChecked}
              disabled={canExtendDisabled}
              className="size-4 border rounded-sm appearance-none cursor-pointer border-custom-200 bg-custom-50 checked:bg-mb-blue checked:border-mb-blue dark:border-custom-800 dark:bg-custom-500/20 dark:checked:bg-mb-blue dark:checked:border-custom-500"
              type="checkbox"
            />
          </div>
          {extendFromOptions.length > 0 && (
            <div className="mb-3">
              <label
                htmlFor="extendFrom"
                className="inline-block mb-2 text-base font-medium"
              >
                Extend From
              </label>
              <Select
                value={
                  extendFrom ? { value: extendFrom, label: extendFrom } : null
                }
                onChange={handleChangeExtendFrom}
                options={extendFromOptions}
                placeholder="Select options"
                className="selectize capitalize"
              />
            </div>
          )}
          <div className="flex justify-end gap-2 mt-4">
            <button
              onClick={toggle}
              className="text-white bg-mb-red border-mb-red btn hover:text-white hover:bg-mb-red/20 hover:border-mb-red/20 focus:text-white focus:bg-mb-red/20 focus:border-mb-red/20 focus:ring focus:ring-red-100 active:text-white active:bg-mb-red/20 active:border-mb-red/20 active:ring active:ring-red-100 dark:ring-custom-400/20"
            >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              type="submit"
              className="text-white transition-all duration-200 ease-linear btn bg-mb-blue border-mb-blue hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
            >
              Add
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ListView;
