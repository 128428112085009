import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface LoginState {
  token: string | null;
  error: string;
  success: boolean;
  isUserLogout: boolean;
}

const initialState: LoginState = {
  token: localStorage.getItem("authToken")? localStorage.getItem("authToken"): null,
  error: "",
  success: false,
  isUserLogout: false,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginSuccess(state: LoginState, action: PayloadAction<string>) {
      state.token = action.payload;
      state.success = true;
      state.error = "";
    },
    loginError(state: LoginState, action: PayloadAction<string | any>) {
      state.error = action.payload;
      state.success = false;
    },
    logoutSuccess(state: LoginState, action: PayloadAction<boolean>) {
      state.isUserLogout = action.payload;
    },
  },
});

export const { loginSuccess, loginError, logoutSuccess } = loginSlice.actions;
export default loginSlice.reducer;
