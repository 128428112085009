import React, { useEffect, useState, useRef } from "react";
import Select from 'react-select';
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { AxiosResponse } from "axios";
import { getDataByAPIPath } from "helpers/mb-api_helper";
import { text } from "stream/consumers";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css"; // Import a Flatpickr theme
import { any } from "bluebird";
import { X } from "lucide-react";

interface Component {
    id: number
    formData: any
    setFormData: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>
}
interface Option {
    readonly label: string;
    readonly value: string;
    readonly isDisabled?: boolean;
  }
interface FilterValues {
    filter: any;
    operator: any;
    value: any;
    uiElement: any;
    apiRef: any;
    fieldCnt: number
}

interface RecordsData {
    [key: string]: any; // Allows any key-value pairs in the user object
  }

const BasicFilterAndSortComponent: React.FC<Component> = ({
    id,
    formData,
    setFormData
}) => {
    const tagInputRef = useRef(null); // Reference to the tag input field
    const selectProperties = createSelector(
        (state: any) => state.User,
        (user) => ({
            meta: user.meta,
        })
    );
    const { meta } = useSelector(selectProperties);
    let validValues = meta.validValues
    let filterOptionsData = meta.filterOptions

    const [searchKeys,setSearchKeys]= useState<string[]>([])
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    let [filterFields, setFilterFields] = useState<Array<FilterValues>>(formData && formData[id]?.filterDetails && formData[id]?.filterDetails.length > 0
        ? formData[id]?.filterDetails
        : [{ filter: '', operator: '', value: [], uiElement: 'multiSelect', apiRef: '', fieldCnt:1 }
        ]);
    let [filterOptions, setFilterOptions] = useState<any>(filterOptionsData.map((filterOption: any) => { return { 'label': filterOption.label, 'value': filterOption.name } }))
    let [operatorOptions, setOperatorOptions] = useState<any>([])
    let [valueOptions, setValueOptions] = useState<any>([])

    let [tagValue, setTagValue] = useState<any>([]);
    let [tags, setTags] = useState<any[]>(formData && formData[id]?.filterDetails
        ? formData[id]?.filterDetails.map((filterOption: any) => { return filterOption.value.value })
        : []
    );
    let [selectedTextBox, setSelectedTextBox] = useState<any>(formData && formData[id]?.filterDetails
        ? formData[id]?.filterDetails.map((filterOption: any) => { return filterOption.value.value })
        : []
    );
    let [selectedDate, setSelectedDate] = useState<any>(formData && formData[id]?.filterDetails
        ? formData[id]?.filterDetails.map((filterOption: any) => { return filterOption.value })
        : []
    );

    let [sortOptions, setSortOptions] = useState<any>([
        { value: 'title', label: 'Title' },
        { value: 'popularity', label: 'Popularity' },
        { value: 'release_date', label: 'Release Date' },
        { value: 'created_date', label: 'Created Date' }
    ])
    let [selectedSortOptions, setSelectedSortOptions] = useState<any>(formData && formData[id]?.sortData
        ? [{ 'value': formData[id].sortData.label, 'label': formData[id].sortData.value }]
        : []
    );
    let [sortValues, setSortValues] = useState<any>([
        { value: 'asc', label: 'Ascending' },
        { value: 'desc', label: 'Descending' }
    ])
    let [selectedSortValues, setSelectedSortValues] = useState<any>(formData[id]?.sortValues
        ? [{ value: formData[id].sortValues.label, label: formData[id].sortValues.value }]
        : []
    )

    const handleTagChange = (index: number, field: string, e: React.ChangeEvent<HTMLInputElement>) => {
        let tagValueNew = [...tagValue]
        tagValueNew[index] = e.target.value
        setTagValue([...tagValueNew])
    };
    
    const handleKeyDown = (index: number, field: string, e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && tagValue[index].trim()) {
            e.preventDefault();
            if(tags[index]){
                if (!tags[index].includes(tagValue[index].trim())) {
                    let tagsNew = [...tags]
                    tagsNew[index] = [...tagsNew[index], tagValue[index].trim()]
                    setTags([...tagsNew]);
                    const newFields = [...filterFields];
                    newFields[index] = { ...newFields[index], [field]: {'value':tagsNew[index]} };
                    setFilterFields(newFields);
                }
            }else{
                let tagsNew = [...tags]
                tagsNew[index] = [tagValue[index].trim()]
                setTags([...tagsNew]);
                const newFields = [...filterFields];
                newFields[index] = { ...newFields[index], [field]: {'value':tagsNew[index]} };
                setFilterFields(newFields);
            }
            
            let tagValueNew = [...tagValue]
            tagValueNew[index] = ''
            setTagValue([...tagValueNew])

            
        }
    };
    
    const handleDeleteChip = (index: number, field: string, tagToDelete: string) => {
        let tagsNew = [...tags]
        tagsNew[index] = tags[index].filter((tag:string) => tag !== tagToDelete)
        setTags([...tagsNew]);

        const newFields = [...filterFields];
        newFields[index] = { ...newFields[index], [field]: {'value':tagsNew[index]} };
        setFilterFields(newFields);
    };

    const handleSortChange = (selected: any) => {
        setSelectedSortOptions({
            label: selected.label,
            value: selected.value
        });
        if (formData[id]) {
            const newData = [...formData];
            newData[id] = {
                ...newData[id], sortData: {
                    label: selected.label,
                    value: selected.value
                }
            };
            setFormData(newData);
        }
    }
    const handleSortValueChange = (selected: any) => {
        setSelectedSortValues({
            label: selected.label,
            value: selected.value
        });
        if (formData[id]) {
            const newData = [...formData];
            newData[id] = {
                ...newData[id], sortValues: {
                    label: selected.label,
                    value: selected.value
                }
            };
            setFormData(newData);
        }
    }


    const addMoreFields = () => {
        setFilterFields([...filterFields, { filter: '', operator: '', value: '', uiElement: 'multiSelect', apiRef: '' , fieldCnt:1}])
    };
    const handleDeleteField = (index: number) => {
        setFilterFields(filterFields.filter((_, i) => i !== index));
        setOperatorOptions(operatorOptions.filter((_:any, i:number) => i !== index));
        setValueOptions(valueOptions.filter((_:any, i:number) => i !== index));
    };
    const handleFieldChange = (index: number, field: string, selected: any) => {
        

        if (field === 'filter') {
            const operatorData = filterOptionsData
            .filter((filterOption: any) => filterOption.name === selected.value)
            .map((filterOption: any) => filterOption.operators)
        
            const valuesData = filterOptionsData
                .filter((filterOption: any) => filterOption.name === selected.value)
                .map((filterOption: any) => filterOption.validValues)

            const arrayUiElement = filterOptionsData
                .filter((filterOption: any) => filterOption.name === selected.value)
                .map((filterOption: any) => filterOption.uiElement)
            const uiElement = arrayUiElement[0];

            const arrayApiRef = filterOptionsData
                .filter((filterOption: any) => filterOption.name === selected.value)
                .map((filterOption: any) => filterOption.apiRef)
            const apiRef = arrayApiRef[0];

            console.log('uiElement--',uiElement)

            let operatorOptionsNew = [...operatorOptions]
            operatorOptionsNew[index] = operatorData[0]
            setOperatorOptions([...operatorOptionsNew])            

            let valueOptionsNew = [...valueOptions]
            valueOptionsNew[index] = valuesData[0]
            setValueOptions([...valueOptionsNew])

            const newFields = [...filterFields];
            newFields[index] = { ...newFields[index], 
                                ['filter']: { value: selected.value, label: selected.label },
                                ['operator']: '',
                                ['value']: '',
                                ['uiElement']: uiElement,
                                ['apiRef']: apiRef,
                            };
            setFilterFields(newFields);
        } else if (field === 'operator') {
            let fieldCnt = 1
            const newFields = [...filterFields];
            if(selected.label=='Between'){
                fieldCnt = 2
            }
            newFields[index] = { ...newFields[index], 
                                    ['operator']: { value: selected.value, label: selected.label },
                                    ['fieldCnt']: fieldCnt
                                }
             setFilterFields(newFields);
        }else if(field === 'value'){

        }
    };
    const handleInputChange = async (index: number, field: string, selected: any, uiElement:any)=>{
        console.log("selected",selected)
        if(uiElement === 'textbox'){
            let selectedTextBoxNew = [...selectedTextBox]
            selectedTextBoxNew[index] = selected.target.value
            setSelectedTextBox([...selectedTextBoxNew])
            const newFields = [...filterFields];
            newFields[index] = { ...newFields[index], [field]: { value: selected.target.value, label: selected.target.value } };
            setFilterFields(newFields);
        }else{
            let selectedTextBoxNew = [...selectedTextBox]
            selectedTextBoxNew[index] = selected[0].value
            setSelectedTextBox([...selectedTextBoxNew])
            const newFields = [...filterFields];
            newFields[index] = { ...newFields[index], [field]: { value: selected[0].value, label: selected[0].label } };
            setFilterFields(newFields);
        }
    }
    useEffect( ()=>{
        console.log('selectedDate',selectedDate);
        
    },[selectedDate] )

    const handleDateChange = async (index: number, field: string, selected: any)=>{
        let selectedDateNew = [...selectedDate]
        selectedDateNew[index] = { ...selectedDateNew[index], [field]: selected[0]}
        setSelectedDate([...selectedDateNew])

        const newFields = [...filterFields];
        newFields[index] = { ...newFields[index], ['value']: selectedDateNew[index]  };
        setFilterFields(newFields);
    }

    const renderFilterInput = (index:any, field:any) => {
        if (field.uiElement=='multiSelect' && field.apiRef) {
            return (
                <>
                <div className=" flex flex-wrap items-center border border-gray-300 rounded-lg p-2 py-1 max-h-16 overflow-y-scroll overflow-x-hidden">
                    <div className="flex flex-wrap items-center ">
                    {tags[index]?.map((tag:string, i:number) => (
                        <div
                        key={i}
                        className="flex items-center bg-mb-blue text-white text-sm px-2 py-1 m-1 rounded-lg"
                        >
                        {tag}
                        <button
                            onClick={() => handleDeleteChip(index, "value", tag)}
                            className="ml-1 text-white hover:text-gray-200 focus:outline-none"
                        >
                            &times;
                        </button>
                        </div>
                    ))}
                    </div>
                    <input
                    type="text"
                    value={tagValue[index]}
                    onChange={(e) =>
                        handleTagChange(index, "value", e)
                    }
                    onKeyDown={(e) =>
                        handleKeyDown(index, "value", e)
                    }
                    placeholder="Type and press Enter..."
                    className="flex-grow p-2 outline-none border-none"
                    />
                </div>
                </>
            )
        }else if(field.uiElement=='multiSelect'){
            return (
                <Select
                    isMulti
                    value={field.value}
                    onChange={(e) =>{
                        console.log("eeeeeeeeeeee",e);
                        let newFilterFields = [...filterFields]
                        newFilterFields[index].value = e
                        setFilterFields([...newFilterFields])
                    }}
                    options={valueOptions[index]}
                    placeholder="Select options"
                    className='capitalize'
                />
            )
        }else if(field.uiElement=='textbox'){
            return (
                <input
                    type="text"
                    value={selectedTextBox[index]}
                    onChange={(e) =>
                        handleInputChange(index, "value", e, field.uiElement)
                    }
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    placeholder="Input Text"
                />
            )
        }else if(field.uiElement=='datepicker' && field.fieldCnt==2){
            return (
                <>
                <div className="flex  gap-2">
                <Flatpickr
                    value={selectedDate[index]?.startDate}
                    onChange={(e:any) =>
                        handleDateChange(index, "startDate", e)
                    }
                    options={{
                        dateFormat: "d/m/Y",
                    }}
                    placeholder="Select Date"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                />
                <Flatpickr
                    value={selectedDate[index]?.endDate}
                    onChange={(e:any) =>
                        handleDateChange(index, "endDate", e)
                    }
                    options={{
                        dateFormat: "d/m/Y",
                    }}
                    placeholder="Select Date"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                />
                </div>
                </>
            )
        }else if(field.uiElement=='datepicker'){
            return (
                <Flatpickr
                    value={selectedDate[index]?.startDate}
                    onChange={(e:any) =>
                        handleDateChange(index, "startDate", e)
                    }
                    options={{
                        dateFormat: "d/m/Y",
                    }}
                    placeholder="Select Date"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                />
            )
        }
        
        // let finalValidValues = validValues[item.key] ? validValues[item.key].map((v:any) => { return { value: v, label: v } }) : []
        // if (item.key === 'text' || item.name === 'text') {
        //     return (
        //         <input
        //             type="text"
        //             value={selectedOptions[item.key]}
        //             onChange={(e:any) => handleChange(e.target.value, item.key)}
        //             placeholder="Enter text"
        //             className="border rounded px-2 py-1"
        //         />
        //     );
        // }
        // if (item.key === "tag" || item.name === "tag") {
        //     return (
        //       <div className="relative border rounded px-2 py-1">
        //         <div className="flex flex-wrap gap-2 mb-2">
        //           {(selectedOptions.tag || []).map((tag:any, index:number) => (
        //             <span
        //               key={index}
        //               className="bg-slate-200 text-slate-800 px-1 py-1 text-sm flex items-center"
        //             >
        //               {tag}
        //               <button
        //                 onClick={() => handleTagRemove(tag)}
        //                 className="ml-2 text-black-200"
        //               >
        //                 x
        //               </button>
        //             </span>
        //           ))}
        //         </div>
        //         <input
        //           ref={tagInputRef}
        //           type="text"
        //           placeholder="Press Enter to add tags"
        //           onKeyDown={(e:any) => handleChange(e, item.key)}
        //           className="w-full border-none outline-none"
        //         />
        //       </div>
        //     );
        //   }

        // return (
        //     <Select
        //         isMulti
        //         value={selectedOptions[item.key]}
        //         onChange={(selected:any) => handleChange(selected, item.key)}
        //         options={finalValidValues}
        //         placeholder="Select options"
        //         className='capitalize'
        //     />
        // );
    }

    const generateFilterString = (filterFields:any)=>{
        let filterString = ''
        filterFields.forEach( (filterData:any)=>{
            console.log('filterData',filterData)
            // console.log('filterData.value',filterData.value)
            if(filterData.filter){
                // console.log('filterData.filter',filterData.value.value)
                let valueString : any = ''
                if(filterData.uiElement=='textbox'){
                    valueString = filterData.value.value
                }else if( filterData.uiElement == 'datepicker'){
                    if(filterData.value.startDate){
                        const date = new Date(filterData.value.startDate);
                        valueString = date.getTime();
                    }
                    if(filterData.value.endDate){
                        const date = new Date(filterData.value.endDate);
                        valueString += ','+date.getTime();
                    }
                }else{
                    if(filterData.value){
                        if( filterData.value.length>0){
                            filterData.value.map( (fValue:any)=>{
                                if(fValue.value && fValue.value!==undefined){
                                    if(valueString==''){
                                        valueString = fValue.value
                                    }else{
                                        valueString += ','+fValue.value
                                    }
                                }
                            })    
                        }else if(filterData.value.value?.length>0) {
                            filterData.value.value.map( (fValue:any)=>{
                                if(fValue && fValue!==undefined){
                                    if(valueString==''){
                                        valueString = fValue
                                    }else{
                                        valueString += ','+fValue
                                    }
                                }
                            })
                        }
                        
                    }
                }
                
                if(filterString==''){
                    filterString = `filters=${filterData.filter.value}${filterData.operator.value}${valueString}`
                }else{
                    filterString += `;${filterData.filter.value}${filterData.operator.value}${valueString}`
                }
            }
        })
        return filterString;
    }
    
    useEffect(() => {
        console.log('filterFields',filterFields);
        
        let filterString = generateFilterString(filterFields)
        let apiPath = `${formData[id].apiPathRef}?${filterString}`
        if (formData[id]) {
            const newData = [...formData];
            newData[id] = { ...newData[id], filterDetails:filterFields, apiPath:apiPath};
            setFormData(newData);
        }
    }, [filterFields])

    useEffect(() => {
        let operatorOptionsNew : any = []
        let valueOptionsNew : any = []
        filterFields.forEach( (fField:any, index:any )=>{
            const operatorData = filterOptionsData
            .filter((filterOption: any) => filterOption.name === fField.filter?.value)
            .map((filterOption: any) => filterOption.operators)
    
            const valuesData = filterOptionsData
                .filter((filterOption: any) => filterOption.name === fField.filter?.value)
                .map((filterOption: any) => filterOption.validValues)
            
            operatorOptionsNew[index] = operatorData[0]
            valueOptionsNew[index] = valuesData[0]
        })

        setOperatorOptions(operatorOptionsNew)            
        setValueOptions(valueOptionsNew)
        
        let filterString = generateFilterString(filterFields)
        let apiPath = `${formData[id].apiPathRef}?${filterString}`
        if (formData[id]) {
            const newData = [...formData];
            newData[id] = { ...newData[id], filterDetails:filterFields, apiPath:apiPath};
            setFormData(newData);
        }
        console.log('filterString',filterString);

    }, [])


    return (
        <>
            <div className="flex justify-between gap-5 ml-5 mr-5 mb-5 mt-10">
                <label className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize font-semibold text-lg">Sort Options</label>
            </div>
            <div className="grid lg:grid-cols-2 gap-4 justify-between ml-5 mr-5 mb-5 border shadow-lg rounded-lg p-4 items-end">
                <div className="">
                    <label className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize">Sort By</label>
                    <Select
                        value={selectedSortOptions}
                        onChange={handleSortChange}
                        options={sortOptions}
                        placeholder="Select options"
                        className='capitalize'
                    />
                </div>
                <div className="">
                    <label className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize">Value</label>
                    <Select
                        value={selectedSortValues}
                        onChange={handleSortValueChange}
                        options={sortValues}
                        placeholder="Select options"
                        className='capitalize'
                    />
                </div>
            </div>
            <div className="flex justify-between gap-5 ml-5 mr-5 mb-5">
                <label className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize font-semibold text-lg">Filter Options</label>
                
            </div>
            <div className="ml-5 mr-5 mb-5 flex flex-col gap-4">
                {filterFields.map((field, index) => {
                    return (
                    <div className="grid lg:grid-cols-6 gap-8 justify-start items-start relative p-4 border shadow-lg rounded-lg">
                        <div className="lg:col-span-2">
                            <label className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize">Filter</label>
                            <Select
                                value={field.filter}
                                onChange={(e: any) =>
                                    handleFieldChange(index, "filter", e)
                                }
                                options={filterOptions}
                                placeholder="Select options"
                                className='capitalize'
                            />
                        </div>
                        <div className="lg:col-span-2">
                            <label className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize">Operator</label>
                            <Select
                                value={field.operator}
                                onChange={(e) =>
                                    handleFieldChange(index, "operator", e)
                                }
                                options={operatorOptions[index]}
                                placeholder="Select options"
                                className='capitalize'
                            />
                        </div>
                        <div className="lg:col-span-2">
                            <label className="mb-0 rtl:ml-2 sm:w-1/4 sm:ltr:mr-2 capitalize">Value</label>
                            {renderFilterInput(index, field)}
                        </div>
                        <div className="absolute z-20 top-1 right-1">
                            {filterFields.length > 1 && (
                                <button
                                    className="btn bg-mb-red text-white p-0.5 rounded-full"
                                    onClick={() => handleDeleteField(index)}
                                >
                                    <X size={12} />
                                </button>
                            )}
                        </div>
                    </div>
                )})}
                 <div className="flex justify-start col-span-2 gap-5 mb-4 mr-4">
                    <button onClick={addMoreFields}
                        className="text-white btn bg-mb-blue border-mb-blue hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"                >
                        Add More
                    </button>
                </div>
            </div>
           
        </>
    );
};

export default BasicFilterAndSortComponent;
