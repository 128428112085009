import React, { useState } from "react";
import isoLangs from "lib/languages";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import RightDrawer from "Layout/RightDrawer";

interface ValObject {
  [key: string]: string; // Adjust according to the fields you have
}

interface I18nEntry {
  lang: String;
  vals: ValObject;
}
interface FormValues {
  i18n: I18nEntry[];
}
interface Component {
  field: string;
  formValues: { [key: string]: any };
  setFormValues: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  element: {
    shortDesc?: string;
    longDesc?: {
      title: string;
      body: string;
    };
    name: string;
    isMultiLingual: boolean;
    cols: number;
  };
  meta: {
    languages: String[];
    lang: String;
  };
}

const TextArea: React.FC<Component> = ({
  element,
  field,
  formValues,
  setFormValues,
  meta,
}) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const handleChange = (event: any) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: event,
    }));
  };

  const handleInputChange = (e: any, editor: any) => {
    const value = editor.getData();
    const activeLang = meta.languages[activeTab];
    setFormValues((prevValues: FormValues) => {
      // Ensure i18n exists and has the current language
      const updatedI18n: I18nEntry[] = prevValues.i18n || [];

      // Find or create the entry for the active language
      const updatedEntries = updatedI18n.map((entry: I18nEntry) => {
        if (entry.lang === activeLang) {
          // Ensure vals object contains the necessary field
          return {
            ...entry,
            vals: {
              ...entry.vals,
              [element.name]: value,
            },
          };
        }
        return entry;
      });

      // If no entry for the active language exists, add one
      const hasActiveLang = updatedEntries.some(
        (entry: I18nEntry) => entry.lang === activeLang
      );

      if (!hasActiveLang) {
        updatedEntries.push({
          lang: activeLang,
          vals: {
            [element.name]: value,
          },
        });
      }

      if (meta.lang === activeLang)
        return { ...prevValues, i18n: updatedEntries, [field]: value };

      return {
        ...prevValues,
        i18n: updatedEntries,
      };
    });
  };

  if (element.isMultiLingual) {
    return (
      <div className={`lg:col-span-${element.cols || 12}`}>
        <div className="flex flex-col gap-1 mb-2">
          <div className="flex items-center gap-2">
            <label className="inline-block text-base font-medium">
              {element.name}
            </label>
            {element.longDesc && (
              <RightDrawer
                title={element.longDesc.title}
                body={element.longDesc.body}
              />
            )}
          </div>
          {element.shortDesc && element.shortDesc !== "" && (
            <span className="text-sm">{element.shortDesc}</span>
          )}
        </div>
        <div className="flex gap-3 flex-wrap">
          {meta.languages.map((lang: any, i: number) => (
            <p
              className={`p-2 ${
                activeTab === i
                  ? "underline text-mb-blue font-bold"
                  : "text-black"
              } `}
              onClick={() => setActiveTab(i)}
            >
              {isoLangs[lang].name}
            </p>
          ))}
        </div>
        <div>
          <CKEditor
            onChange={handleInputChange}
            editor={ClassicEditor}
            onReady={(editor) =>
              editor.setData(
                formValues.i18n
                  ? formValues.i18n.find(
                      (langObj: I18nEntry) =>
                        langObj.lang === meta.languages[activeTab]
                    )?.vals[element.name] || ""
                  : ""
              )
            }
            data={
              formValues.i18n
                ? formValues.i18n.find(
                    (langObj: I18nEntry) =>
                      langObj.lang === meta.languages[activeTab]
                  )?.vals[element.name] || ""
                : ""
            }
          />
        </div>
      </div>
    );
  }

  return (
    <div className="lg:col-span-2 xl:col-span-12">
      <div className="flex flex-col gap-1 mb-2">
        <div className="flex items-center gap-2">
          <label className="inline-block text-base font-medium">
            {element.name}
          </label>
          {element.longDesc && (
            <RightDrawer
              title={element.longDesc.title}
              body={element.longDesc.body}
            />
          )}
        </div>
        {element.shortDesc && element.shortDesc !== "" && (
          <span className="text-sm">{element.shortDesc}</span>
        )}
      </div>

      <CKEditor
        onChange={handleChange}
        editor={ClassicEditor}
        data={formValues[field] || ""}
      />
    </div>
  );
};

export default TextArea;
