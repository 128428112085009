import React, { useEffect } from "react";

interface SnackBarProps {
  message: string;
  duration?: number;
  onClose: () => void;
}

const SnackBar: React.FC<SnackBarProps> = ({
  message,
  duration = 3000,
  onClose,
}) => {
  useEffect(() => {
    const timer = setTimeout(onClose, duration);
    return () => clearTimeout(timer);
  }, [duration, onClose]);

  return (
    <div className="z-40  fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-mb-blue text-white px-4 py-2 rounded-lg shadow-lg flex items-center">
      <button onClick={onClose} className="text-white mr-2 focus:outline-none">
        &times;
      </button>
      <span>{message}</span>
    </div>
  );
};

export default SnackBar;
