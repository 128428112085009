import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import TableContainer from "Common/TableContainer";
import BreadCrumb from "Common/BreadCrumb";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import Select from "react-select";
import {
  getPlatforms,
  createPlatform,
  getPlatformByPageNumber,
} from "helpers/mb-api_helper";
import { slugify } from "helpers/utility";

// Icons
import {
  Search,
  Plus,
  FileEdit,
  CheckCircle,
  Loader,
  AlignJustify,
  X,
} from "lucide-react";
import Modal from "Common/Components/Modal";
import isoLangs from "lib/languages";
import DropdownWithCheckbox from "components/formComponets/DropdownWithCheckbox";
import Pagination from "Layout/Pagination";
import { CircleAlert } from "Layout/custom icons";

const tableHeadersOptions = [
  { label: "Name", value: "name" },
  { label: "Status", value: "status" },
  { label: "Id", value: "id" },
  { label: "Slug", value: "slug" },
  { label: "Category", value: "category" },
  { label: "UpdateDate", value: "updateDate" },
  { label: "Language", value: "language" },
];

// columns
const Status = ({ item }: any) => {
  switch (item) {
    case "live":
      return (
        <span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-mb-green dark:bg-mb-green/20 dark:border-transparent inline-flex items-center status">
          <CheckCircle className="size-3 mr-1.5" />
          {item}
        </span>
      );
    case "draft":
      return (
        <span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-slate-100 border-transparent text-slate-500 dark:bg-slate-500/20 dark:text-zink-200 dark:border-transparent status">
          <Loader className="size-3 mr-1.5" />
          {item}
        </span>
      );
    default:
      return (
        <span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-mb-green dark:bg-mb-green/20 dark:border-transparent inline-flex items-center status">
          <CheckCircle className="size-3 mr-1.5" />
          {item}
        </span>
      );
  }
};

const ListPlatform: React.FC = () => {
  const [platforms, setPlatforms] = useState<any>([]); // State to store platforms data
  const [totalCount, setTotalCount] = useState<number>(0); // State to store total
  const [colData, setColData] = useState<any>([]);
  const [tableHeaders, setTableHeaders] = useState([
    "id",
    "name",
    "slug",
    "status",
  ]);
  const [pageNumber, setPageNumber] = useState(1);
  const [show, setShow] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [slug, setSlug] = useState<any>("");
  const [id, setId] = useState<any>("");
  const [status, setStatus] = useState<string>("draft");
  const [extendFrom, setExtendFrom] = useState<any>(null);
  const [canExtend, setCanExtend] = useState<boolean>(false);
  const [canExtendDisabled, setCanExtendDisabled] = useState<boolean>(false);
  const [canExtendChecked, setCanExtendChecked] = useState<any>("");
  const [extendFromOptions, setExtendFromOptions] = useState<any>([]);

  const [pageSize, setPageSize] = useState<any>(20);

  const columnObject: any = useMemo(
    () => ({
      name: {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
      },
      slug: {
        header: "Slug",
        accessorKey: "slug",
        enableColumnFilter: false,
      },
      id: {
        header: "Id",
        accessorKey: "id",
        enableColumnFilter: false,
      },
      updateDate: {
        header: "UpdateDate",
        accessorKey: "updateDate",
        enableColumnFilter: false,
      },
      genres: {
        header: "Genres",
        accessorKey: "genres",
        enableColumnFilter: false,
        cell: (cell: any) => cell.row.original.genres?.join(" | "),
      },
      category: {
        header: "Category",
        accessorKey: "category",
        enableColumnFilter: false,
        cell: (cell: any) => cell.row.original.catogory?.join(" | "),
      },
      language: {
        header: "Language",
        accessorKey: "defaultLanguage",
        enableColumnFilter: false,
        cell: (cell: any) =>
          cell.row.original.defaultLanguage
            ? isoLangs[cell.row.original.defaultLanguage]["nativeName"]
            : "",
      },
      status: {
        header: "Status",
        accessorKey: "v_status",
        enableColumnFilter: false,
        cell: (cell: any) => <Status item={cell.getValue()} />,
      },
      action: {
        header: "Action",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex gap-4">
            <Link
              className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
              to={`/platforms/views/${cell.row.original.id}`}
            >
              <FileEdit className="inline-block size-3 ltr:mr-1 rtl:ml-1" />
              {""}
              <span className="align-middle">Edit</span>
            </Link>
            <Link
              className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
              to={`/platforms/menus/${cell.row.original.id}`}
            >
              <AlignJustify className="inline-block size-3 ltr:mr-1 rtl:ml-1" />
              <span className="align-middle">Menu</span>
            </Link>
          </div>
        ),
      },
    }),
    []
  );

  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
      setName("");
      setSlug("");
      setId("");
      setExtendFrom(null);
      setCanExtend(false);
      setCanExtendChecked(false);
      setCanExtendDisabled(false);
    } else {
      setShow(true);
    }
  }, [show]);

  const handleHeaderOptionChange = (selectedOption: string[]) => {
    setTableHeaders(selectedOption);
  };

  const handlePageChange = async (value: number) => {
    if (value !== pageNumber)
      try {
        const response: any = await getPlatformByPageNumber(value);
        setPlatforms(response.list);
        setTotalCount(response.total);
        setPageNumber(value);
      } catch (e) {
        console.error("Error fetching assets:", e);
      }
  };

  let handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      let data = {
        name: name,
        slug: slug,
        id: id,
        v_can_extend: canExtend,
        v_status: status,
        v_parent: extendFrom,
      };
      const response: any = await createPlatform(JSON.stringify(data)); // Await the API call to create platforms
      console.log(data);
      setPlatforms((prev: any) => [response, ...prev]);
      setShow(false);
      setName("");
      setSlug("");
      setId("");
      setExtendFrom(null);
      setCanExtend(false);
      setCanExtendChecked(false);
      setCanExtendDisabled(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log(canExtend, canExtendDisabled, canExtendChecked);
  }, [canExtend, canExtendDisabled, canExtendChecked]);

  const handleChangeName = (event: any) => {
    setName(event.target.value);
    setSlug(slugify(event.target.value));
    setId(slugify(event.target.value));
  };
  let handleChangeCanExtend = (event: any) => {
    console.log(event.target.checked);
    setCanExtend(event.target.checked);
    setCanExtendChecked(event.target.checked);
  };
  let handleChangeExtendFrom = (selected: any) => {
    setExtendFrom(selected.value);
    setCanExtend(false);
    setCanExtendChecked(false);
    setCanExtendDisabled(true);
  };

  useEffect(() => {
    setColData(tableHeaders.map((header: any) => columnObject[header]));
  }, [tableHeaders, columnObject]);

  useEffect(() => {
    const getPlatformData = async () => {
      try {
        const response: any = await getPlatforms(); // Await the API call to fetch platforms
        setPlatforms(response.list); // Extract the data from the response and set it in the state
        setTotalCount(response.total);
      } catch (error) {
        console.error("Error fetching platforms:", error);
      }
    };
    const getFilteredPlatformData = async () => {
      try {
        let filters: any = {
          v_can_extend: true,
        };

        const response: any = await getPlatforms(filters); // Await the API call to fetch platforms
        console.log("platforms", response.list);
        setExtendFromOptions(
          Object.keys(response.list).map((v) => {
            return { value: response.list[v].id, label: response.list[v].id };
          })
        );
      } catch (e) {
        console.log(e);
      }
    };
    getPlatformData(); // Call the async function to fetch plaforms
    getFilteredPlatformData(); // Call the async function to fetch plaforms with filter
  }, []);

  return (
    <React.Fragment>
      <BreadCrumb title="Manage Platforms" pageTitle="Storefront" />
      {/* <ToastContainer closeButton={false} limit={1} /> */}
      <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
        <div className="xl:col-span-12">
          <div className="card" id="usersTable">
            <div className="card-body">
              <div className="flex items-center">
                <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                  <div className="relative xl:col-span-2">
                    <input
                      type="text"
                      className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      placeholder="Search platforms..."
                      autoComplete="off"
                    />
                    <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                  </div>
                </div>
                <div className="shrink-0">
                  <button
                    type="button"
                    className="text-white btn bg-mb-blue border-mb-blue hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                    onClick={toggle}
                  >
                    <Plus className="inline-block size-4" />{" "}
                    <span className="align-middle">Add Platform</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="flex justify-end px-4">
              <DropdownWithCheckbox
                options={tableHeadersOptions}
                selectedOptions={tableHeaders}
                onChange={handleHeaderOptionChange}
                disabledValues={["name", "status"]}
              />
            </div>
            <div className="card-body">
              {platforms && platforms.length > 0 ? (
                <>
                  <TableContainer
                    isPagination={false}
                    columns={[...colData, columnObject.action] || []}
                    data={platforms || []}
                    customPageSize={
                      platforms.length > pageSize ? pageSize : platforms.length
                    }
                    divclassName="overflow-x-auto"
                    tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                    theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-mb-blue [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
                    thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
                    tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5"
                    PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
                  />
                  <div className="w-full flex justify-between">
                    <div>
                      <p className="text-sm text-gray-600 dark:text-gray-300">
                        Showing result {platforms?.length} out of {totalCount}
                      </p>
                    </div>
                    <Pagination
                      totalCount={totalCount}
                      pageNumber={pageNumber}
                      showResult={20}
                      handlePageChange={handlePageChange}
                    />
                  </div>
                </>
              ) : (
                <div className="noresult">
                  <div className="py-6 text-center">
                    <Search className="size-6 mx-auto text-mb-blue fill-sky-100 dark:mb-blue/20" />
                    <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* add platform Modal */}
      <Modal
        show={show}
        onHide={toggle}
        id="defaultModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-mb-red"
        >
          <Modal.Title className="text-16">Add Platform</Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <div className="mb-3">
            <div className="flex gap-3 items-center mb-2">
              <label
                htmlFor="name"
                className="inline-block  text-base font-medium"
              >
                Name
              </label>
              <div data-tooltip-id="default1">
                <CircleAlert w={12} h={12} />
              </div>
              <Tooltip id="default1" place="top" content="Default Tooltips" />
            </div>
            <input
              type="text"
              id="name"
              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              placeholder="Enter name"
              name="name"
              onChange={handleChangeName}
              value={name}
            />
          </div>
          <div className="flex gap-10 mb-3">
            <label htmlFor="v_can_extend" className="align-middle">
              Can Extend
            </label>
            <input
              id="v_can_extend"
              onChange={handleChangeCanExtend}
              checked={canExtendChecked}
              disabled={canExtendDisabled}
              className="size-4 border rounded-sm appearance-none cursor-pointer border-custom-200 bg-custom-50 checked:bg-mb-blue checked:border-mb-blue dark:border-custom-800 dark:bg-custom-500/20 dark:checked:bg-mb-blue dark:checked:border-custom-500"
              type="checkbox"
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="extendFrom"
              className="inline-block mb-2 text-base font-medium"
            >
              Extend From
            </label>
            <Select
              value={
                extendFrom ? { value: extendFrom, label: extendFrom } : null
              }
              onChange={handleChangeExtendFrom}
              options={extendFromOptions}
              placeholder="Select options"
              className="selectize capitalize"
            />
          </div>
          <div className="flex justify-end gap-2 mt-4">
            <button
              onClick={toggle}
              className="text-white bg-mb-red border-mb-red btn hover:text-white hover:bg-mb-red/20 hover:border-mb-red/20 focus:text-white focus:bg-mb-red/20 focus:border-mb-red/20 focus:ring focus:ring-red-100 active:text-white active:bg-mb-red/20 active:border-mb-red/20 active:ring active:ring-red-100 dark:ring-custom-400/20"
            >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              type="submit"
              className="text-white transition-all duration-200 ease-linear btn bg-mb-blue border-mb-blue hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
            >
              Add
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ListPlatform;
